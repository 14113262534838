import Vue from 'vue'
//Vue.config.devtools = true

import App from './App.vue'
import router from "./router";
import Buefy from 'buefy'
import './css/custom_css.scss'
import InstantSearch from 'vue-instantsearch';
import { store } from './store.js'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faCoffee, faChild, faCircle, faArchive, faUpload, faTimesCircle } from '@fortawesome/free-solid-svg-icons'
import { faComment } from '@fortawesome/free-regular-svg-icons'
import { faFacebookF, faTwitter } from '@fortawesome/free-brands-svg-icons'
import { FontAwesomeIcon, FontAwesomeLayers, FontAwesomeLayersText } from '@fortawesome/vue-fontawesome'
import VueMeta from 'vue-meta'
import i18n from './i18n'
const axios = require('axios')
const route = "https://us-central1-wavy-ressources.cloudfunctions.net/app"
//const route = 'http://localhost:5001/wavy-ressources/us-central1/app/'

const md5 = require('md5')
Vue.use(VueMeta, {
  refreshOnceOnNavigation: true
})


library.add(
  faCoffee,
  faChild,
  faCircle,
  faArchive,
  faComment,
  faTwitter,
  faUpload,
  faTimesCircle,
  faFacebookF
)

Vue.component('font-awesome-icon', FontAwesomeIcon)
Vue.component('font-awesome-layers', FontAwesomeLayers)
Vue.component('font-awesome-layers-text', FontAwesomeLayersText)

const fb = require('../firebaseConfig')
Vue.use(InstantSearch);

Vue.config.productionTip = false
Vue.use(Buefy,{ defaultIconPack: 'fas' })

fb.auth.onAuthStateChanged(user => {
  if (user) {
    console.log('onstateuserchange')
    fb.auth.currentUser.getIdToken(true).then(function(idToken) {
    axios.get(`${route}/user/${md5(user.email.toLowerCase())}/?token=${idToken}`)
                .then(function (response) {
                  store.dispatch("fetchUserDetails", response.data);
                  console.log(response)
                  console.log("ok user updated")
                  console.log("end of get User")
                  store.dispatch("fetchUser", user)
                  if(store.state.user.company && store.state.user.company.status == "Client"){
                    console.log("update status Main.js")
                    store.dispatch("updateStatus", "gold")
                  }
                  else if(store.state.user.data.details && store.state.user.data.details.phone_number && store.state.user.data.details.statut && store.state.user.data.details.city && store.state.user.data.details.zipcode && store.state.user.data.details.nom_du_salon && store.state.user.data.details.type_de_salon){
                    store.dispatch("updateStatus", "silver")
                  }
                  else {
                    store.dispatch("updateStatus", "bronze")
                  }
                })
      });
     
  }
  else {
  store.dispatch("fetchUser", null);
  }
});

new Vue({
  el: '#app',
  router,
  store,

  data: {
  },

  i18n,
  render: h => h(App)
})

