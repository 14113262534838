<template>
  <div>
      <h1 class="home-title">
        {{$t('login.ongoing-connexion')}}
      <b-loading :is-full-page="true" v-model="isLoading" :can-cancel="false"></b-loading>

      </h1>
  </div>
</template>

<script>
const route = "https://us-central1-wavy-ressources.cloudfunctions.net/app"

//const route = 'http://localhost:5001/wavy-ressources/us-central1/app/'
const fb = require('../../../firebaseConfig')
const axios = require('axios');
const md5 = require('md5')

export default {
  name: 'Home',
  data (){
    return {
      user:{},
      isLoading:true
    }
  },
  components:{
    },
  props: {
  },
  methods:{
    getCompany(companies, here, email, token, index){
              console.log("enter company ")
      if(companies.length > 0 && companies[index]){
        console.log("enter company"+index)
        axios.get(`${route}/company/${md5(email.toLowerCase())}/${companies[index].id}/?token=${token}`)
        .then(function (response) {
          console.log("response company"+index)
          if(response.data.error){
            console.log(response.data.error)
            here.user.companies = {}
            here.user.companies.status = "Non client"
          } else {

          
          here.user.companies[index] = response.data
          if(index < companies.length - 1){
            index++
            here.getCompany(companies, here, email.toLowerCase(), token, index)
          }
          else {
            let isCustomer = here.user.companies.find(o => o.status[0] === 'Client')
            let isOldCustomer = here.user.companies.find(o => o.status[0] === 'Ancien client')
            let otherCompany = here.user.companies.find(o => o.status[0] !== 'Ancien client' && o.status[0] !== 'Client')

            if(isCustomer){
              console.log("status gold LoggedIn")
              here.user.companies = isCustomer
              here.user.companies.status = "Client"
              here.user.status = "gold"
              here.$store.dispatch("updateStatus", "gold")

            } else if(isOldCustomer){
              here.user.companies = isOldCustomer
              here.user.companies.status = "Ancien client"
            } 
            else {
              here.user.companies = otherCompany[0]
              here.user.companies.status = "Autre"
            }
            }
            here.$store.dispatch("fetchCompany", here.user.companies)
            if(window.localStorage.getItem('route')){
              let route = window.localStorage.getItem('route')
              window.localStorage.removeItem('route')
                here.$router.push({ path: `${route}` })          
            } else {
              here.$router.push({ path: `/` })
            }
           
          }
        }).catch(function(err){
          console.log(err)
          index++
          here.getCompany(companies, here, email.toLowerCase(), token, index)
        })
        
      }else {
        if(window.localStorage.getItem('route')){
                  console.log("route ")

        let route = window.localStorage.getItem('route')
        window.localStorage.removeItem('route')
          here.$router.push({ path: `${route}` })      
      } else {
                          console.log("no route ")

        here.$router.push({ path: "/" })
      }
      }
      
    }
  },
  created(){
    
    let here = this
    if (fb.auth.isSignInWithEmailLink(window.location.href)) {
      let email = window.localStorage.getItem('emailForSignIn');
      if (!email) {
        email = window.prompt(here.$t('login.remail'));
      }
     
      fb.auth.signInWithEmailLink(email, window.location.href)
        .then(function(currentUser) {
          here.$store.dispatch("fetchUser", currentUser.user);
          window.localStorage.removeItem('emailForSignIn');
           fb.auth.currentUser.getIdToken(true).then(function(idToken) {
               window.localStorage.setItem('token', idToken )
                axios.get(`${route}/user/${md5(email.toLowerCase())}/?token=${idToken}`)
                .then(function (response) {
                  if(response.data.todo){
                     axios.post(`${route}/user/create/?token=${idToken}`, {"email":email.toLowerCase(), "md5":md5(email.toLowerCase())})
                      .then(function (response) {
                        console.log("user => ")
                        console.log(response.data)
                        here.user = response.data
                         here.$store.dispatch("fetchUserDetails", response.data);
                      })
                      
                  }else{
                  here.user = response.data
                  here.$store.dispatch("fetchUserDetails", here.user);
                }
                window.userengage("event.[Cold]_SignUp_Platform",{
                    "Value": "verified"
                  })
                  window.fbq('track', 'CompleteRegistration');
                  console.log('end user')
                  console.log(here.user)
                  if(here.user.companies){
                   
                  here.getCompany(response.data.companies, here,email.toLowerCase(), idToken, 0)
                   }
                    if(window.localStorage.getItem('route')){
                              console.log("route ")

                    let route = window.localStorage.getItem('route')
                    window.localStorage.removeItem('route')
                      here.$router.push({ path: `${route}` })      
                    } else {
                                      console.log("no route ")

                    here.$router.push({ path: "/" })
                  }
                })
              
            }).catch(function(error) {
              console.log(error)
            });
        })
        .catch(function(error) {
          console.log(error)
        });
    }
  }
}
</script>

