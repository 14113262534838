<template>
  <div>
    <div class="container" style="margin-bottom:50px;">
      <h2 class="home-title">
        Ajouter un contenu
      </h2>
      <div class="container">
        <div class="columns">
            <div class="is-6 column">
                <div class="columns">
                    <div class="column is-6">
                        <b-field label="Type de contenu">
                            <b-autocomplete
                                placeholder="ex : Fiche pratique"
                                :keep-first="false"
                                :open-on-focus="true"
                                :data="filteredDataObj"
                                field="name"
                                @select="option => (content.type = option.id)"
                                :clearable="true"
                            >
                            </b-autocomplete>
                        </b-field>
                    </div>
                    <div class="column is-6" v-if="content.type == 'kit' || content.type == 'visuel' ">
                        <b-field label="Plateforme du kit">
                            <b-taginput
                                v-model="content.plateforme"
                                :data="[{'title':'Facebook'}, {'title':'Instagram'}]"
                                autocomplete
                                :allow-new="false"
                                :open-on-focus="true"
                                field="title"
                                placeholder="Selectionner la / les plateforme du kit"
                                >
                            </b-taginput>
                        </b-field>
                    </div>
                </div>
                <div class="columns">
                    <div class="column is-6">
                        <b-field label="Langue du contenu">
                           <b-select placeholder="ex :Français"  expanded v-model="language">   
                                <option value="fr">🇫🇷  Français</option>
                                <option value="es">🇪🇸  Espagnol</option>
                                <option value="en">🇬🇧  Anglais </option>
                            </b-select>
                        </b-field>
                    </div>
                </div>
                <div class="columns">
                    <div class="column is-6">
                        <b-field label="Nom du contenu">
                            <b-input v-model="content.title"></b-input>
                        </b-field>
                    </div>
                    <div class="column is-6">
                        <b-field label="Accès au contenu">
                            <b-autocomplete
                                placeholder="ex : Fiche pratique"
                                :keep-first="false"
                                :open-on-focus="true"
                                :data="[{'id':'gold', 'title':'Clients'},{'id':'silver', 'title':'Inscription complète'}, {'id':'bronze', 'title':'Inscription incomplète'}]"
                                field="title"
                                @select="option => (content.authorization = option.id)"
                                :clearable="true"
                            >
                            </b-autocomplete>
                        </b-field>
                    </div>
                </div>
                <div class="columns" v-if="content.type == 'fp' || content.type == 'kit' || content.type == 'magazine' || content.type == 'lb' || content.type == 'calcul'">
                    <div class="column is-12">
                        <b-field label="Description" >
                            <b-input maxlength="200" v-model="content.description" type="textarea"></b-input>
                        </b-field>
                    </div>
                </div>
                <div class="columns" v-if="content.type == 'fp' || content.type == 'lb' || content.type == 'calcul'">
                    <div class="column is-12">
                        <b-field label="Catégorie">
                            <b-autocomplete
                                placeholder="ex : Se developper"
                                :keep-first="false"
                                :open-on-focus="true"
                                :data="categories"
                                field="name"
                                @select="categorie => (content.tag = categorie.name)"
                                :clearable="true"
                            >
                            </b-autocomplete>
                        </b-field>
                    </div>
                </div>
                <div class="columns" v-if="content.type == 'kit' || content.type == 'magazine' ">
                    <div class="column is-12">
                        <b-field label="Thème">
                            <b-autocomplete
                                placeholder="ex : Halloween"
                                :keep-first="false"
                                :open-on-focus="true"
                                :data="themes"
                                field="name"
                                @select="theme => (content.theme = theme.name)"
                                :clearable="true"
                            >
                            </b-autocomplete>
                        </b-field>
                    </div>
                </div>
                <div class="columns" >
                    <div class="column is-6" v-if="content.type !== 'calcul'">
                        <b-field label="Url du contenu" >
                            <b-input v-model="content.url"></b-input>
                        </b-field>
                    </div>
                    <div class="column is-6" v-if="content.type !== 'calcul' && content.type !== 'video'">
                        <b-field label="Url du thumbnail">
                            <b-input
                            v-model="content.thumbnail"
                            ></b-input>
                            <b-button type="is-primary" class="card-button" @click="uploadVisuels" style="max-width:20%; margin-left:20%"><font-awesome-icon icon="upload" /></b-button>

                            
                        </b-field>
                    </div>
                </div>
                <div class="columns">
                    <div class="column is-12" v-if="content.type == 'fp' || content.type == 'lb' ">
                        <b-field label="Une vidéo liée à la Fiche ? ">
                            <b-input v-model="content.link_video"></b-input>
                        </b-field>
                    </div>
                    <div class="column is-12" v-if="content.type == 'kit'">
                        <b-button type="is-primary" class="card-button" @click="uploadVisuels" disabled>Enregistrer pour ajouter des images</b-button>
                    </div>
                </div>
                <div class="columns">
                    <div class="column is-12"  v-if="content.type == 'fp' || content.type == 'lb' || content.type == 'magazine' || content.type == 'visuel' || content.type == 'calcul'">
                        <b-field label="Contenus associés">
                            <b-taginput
                                v-model="templink_contents"
                                :data="second_search"
                                autocomplete
                                :allow-new="false"
                                :open-on-focus="true"
                                field="title"
                                placeholder="Ajouter des contenus associés"
                                @typing="getFilteredTags">
                            </b-taginput>
                        </b-field>
                    </div>
                </div>
                 <div class="columns is-centered">
                    <div class="column is-6">
                        <b-button type="is-primary" class="card-button" @click="saveContent('add')" >Enregistrer et nouveau contenu</b-button>
                    </div>
                    <div class="column is-6">
                        <b-button type="is-primary" class="card-button" @click="saveContent('see')" >Enregistrer et voir le contenu</b-button>
                    </div>
                 </div>
            </div>
             <div class="column is-1">
             </div>
            <div class="column is-4">
                <CardKit v-if="content.type=='kit'" :content="content" ></CardKit>
                <CardFiche v-if="content.type=='fp'" :content="content"  ></CardFiche>
                <CardMagazine v-if="content.type=='magazine'" :content="content"  ></CardMagazine>
                <CardVisuel v-if="content.type=='visuel'" :content="content" ></CardVisuel>
            </div>
        </div>
    </div>
    </div>
  </div>
</template>

<script>
import CardKit from '../blocs/card_kit'
import CardMagazine from '../blocs/card_magazine'
import CardFiche from '../blocs/card_fiche'
import CardVisuel from '../blocs/card_visuel'
import algoliasearch from 'algoliasearch';
const client = algoliasearch('BOZ2ATNMBB', 'f661c7a09a74e550ea676b661ebfd06c');
const fb = require('../../../firebaseConfig')
const index = client.initIndex('content');

export default {
    name: 'addContent',
    data (){
        return {
            language:"fr",
            type:"",
            templink_contents:[],
            templink_visuels:[],
            content:{
                type:"",
                tag:"",
                link_contents:[],
                description:"",
                title:"",
                link_video:"",
                url:"",
                thumbnail:"",
                image:"",
                creationDate:new Date(),
                updatedAt:new Date(),
                slug:"",
                plateforme:[],
                theme:"",
                authorization:[],
                language:"fr"

            },
            data_type:[
                {"name":"Fiche Pratique", "id":"fp"},
                {"name":"Livre Blanc", "id":"lb"},
                {"name":"Vidéo", "id":"video"},
                {"name":"Kit", "id":"kit"},
                {"name":"Visuel", "id":"visuel"},
                {"name":"Magazine", "id":"magazine"}
            ],
            themes:[
            ],
            categories:[],
            selected:null,
            searchClient: algoliasearch(
                'BOZ2ATNMBB',
                'f661c7a09a74e550ea676b661ebfd06c'
            ),
            results_search:[],
            second_search:[],
            stringSearch:''
        }
    },
    components:{
        CardKit,
        CardMagazine,
        CardFiche,
        CardVisuel
    },
    computed: {
        filteredDataObj() {
            return this.data_type.filter(option => {
                return (
                    option.name
                        .toString()
                        .toLowerCase()
                        .indexOf(this.type.toLowerCase()) >= 0
                )
            })
        },
        
    },
    watch:{
        language:function(val){
            this.categories = []
            this.themes = []
            this.content.language = val
            this.getThemesAndCategories()
        }
    },
    created(){
        if(!this.$store.state.user.data.email.includes('wavy.co')){
            this.$router.push('/')
        }
        let here = this
            fb.categoriesCollection.orderBy('name', 'asc').get().then(function(docs) {
          docs.forEach(doc => {
              var contentTemp = doc.data()
              contentTemp.id = doc.id
              here.categories.push(contentTemp)
          });
        })
         fb.themesCollection.orderBy('date', 'asc').get().then(function(docs) {
          docs.forEach(doc => {
              var contentTemp = doc.data()
              contentTemp.id = doc.id
                here.themes.push(contentTemp)
          });
        })
        let userScript = document.createElement('script')
        userScript.setAttribute('src', 'https://widget.cloudinary.com/v2.0/global/all.js')
        userScript.setAttribute('type', 'text/javascript')
        document.head.appendChild(userScript)
    },
    methods:{
        insertString(str, index, value){
            return str.substr(0, index) + value + str.substr(index);
        },
        getThemesAndCategories:function(){
            let here = this
            fb.categoriesCollection.orderBy('name', 'asc').get().then(function(docs) {
          docs.forEach(doc => {
              var contentTemp = doc.data()
              contentTemp.id = doc.id
              here.categories.push(contentTemp)
          });
        })
         fb.themesCollection.orderBy('date', 'asc').get().then(function(docs) {
          docs.forEach(doc => {
              var contentTemp = doc.data()
              contentTemp.id = doc.id              
              if(contentTemp.language == here.language){
                here.themes.push(contentTemp)
              }
          });
        })
        },
       uploadVisuels: function() {
            let here = this
            var myWidget = window.cloudinary.createUploadWidget({
            cloudName: 'wavy', 
            uploadPreset: 'upload_vignettes',showCompletedButton: true}, (error, result) => { 
                if (!error && result && result.event === "success") { 
                here.content.thumbnail = this.insertString(result.info.secure_url, result.info.secure_url.indexOf('upload/')+6, '/c_limit,h_450,w_300')                
                } 
            }
            )
            myWidget.open()
           
        },
        getFilteredTags(text) {
            this.stringSearch = text
            if(this.content.type == 'visuel'){
                index.search(this.stringSearch,{filters: 'type:kit'} ).then(({ hits }) => {
                    this.results_search = hits
                });
            }
            if(this.content.tag && this.content.tag != ""){
                index.search(this.content.tag).then(({ hits }) => {
                    this.results_search = hits
                });
            }
            this.second_search = this.results_search.filter((option) => {
                return option.title
                    .toString()
                    .toLowerCase()
                    .indexOf(text.toLowerCase()) >= 0
            })
        },
        saveContent(next){
            let here = this
            var n = here.content.url.lastIndexOf('/');
            here.content.name = here.content.url.substring(n + 1);

            this.content.slug = this.sanitizeTitle(this.content.title)
            this.templink_contents.forEach(element => {
                this.content.link_contents.push(element['objectID'])
            });
            this.templink_visuels.forEach(element => {
                this.content.link_visuels.push(element['objectID'])
            });
            if(here.content.type != 'visuel'){
            fb.contentsCollection.add(here.content)
            .then((docRef) => {
                here.content.objectID = docRef.id
                index.saveObject(here.content, {
            autoGenerateObjectIDIfNotExist: false
            }).then(( ) => {
                     here.$buefy.notification.open({
                            message: 'Modification enregistrées !',
                            type: 'is-bcd-green',
                            duration:3000
                        });
                        if(next == 'add'){
                            window.location.reload();
                        } else {
                            window.location.replace(`/admin/content/edit/${here.content.slug}`)                            
                        }
                        
                }).catch((er) => {
                    console.log(er)
                })
            }).catch((er) => {
                    console.log(er)
                })
            }else{
                fb.visuelsCollection.add(here.content)
            .then(() => {
                    here.$buefy.notification.open({
                    message: 'Modification enregistrées !',
                    type: 'is-bcd-green',
                    duration:3000
                    });
                    window.location.reload();
                }).catch((er) => {
                    console.log(er)
                })
            }
        },
        sanitizeTitle: function(title) {
            var slug = "";
            // Change to lower case
            var titleLower = title.toLowerCase();
            // Letter "e"
            slug = titleLower.replace(/e|é|è|ẽ|ẻ|ẹ|ê|ế|ề|ễ|ể|ệ/gi, 'e');
            // Letter "a"
            slug = slug.replace(/a|á|à|ã|ả|ạ|ă|ắ|ằ|ẵ|ẳ|ặ|â|ấ|ầ|ẫ|ẩ|ậ/gi, 'a');
            // Letter "o"
            slug = slug.replace(/o|ó|ò|õ|ỏ|ọ|ô|ố|ồ|ỗ|ổ|ộ|ơ|ớ|ờ|ỡ|ở|ợ/gi, 'o');
            // Letter "u"
            slug = slug.replace(/u|ú|ù|ũ|ủ|ụ|ư|ứ|ừ|ữ|ử|ự/gi, 'u');
            // Letter "d"
            slug = slug.replace(/đ/gi, 'd');
            // Trim the last whitespace
            slug = slug.replace(/\s*$/g, '');
            // Change whitespace to "-"
            slug = slug.replace(/\s+/g, '-');
            slug = slug.replace(/[&\\#,+()$~%.'":*?<>{}]/g,'');
            
        return slug;
        }
    },
    props: {

    }
}
</script>

