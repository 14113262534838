<template>
<span class="hideDesktop">
    <b-navbar >
        <template slot="brand">
            <b-navbar-item tag="router-link" :to="{ path: '/' }">
                <img
                    src="https://res.cloudinary.com/wavy/image/upload/v1558086078/wavy-home/landing_page/WAVY_LOGOTYPE2_BLEU2.png"
                    alt="Wavy Ressources"
                >
            </b-navbar-item>
        </template>
        <template slot="start">
            <span @click="kitOpen = !kitOpen">
            <b-navbar-dropdown label="Kits Réseaux Sociaux" >
                <router-link to="/kits" v-if="kitOpen" >
                    <b-navbar-item class="menu-subtitle" >
                        {{ $t('menu.see-all-kit') }}
                    </b-navbar-item>
                </router-link>
                <router-link :to="'/kit/theme/'+theme.id" v-for="theme in themes" v-bind:key="theme.id" v-show="kitOpen">
                    <b-navbar-item>
                        {{theme.name}}
                    </b-navbar-item>
                </router-link>
            </b-navbar-dropdown>
            </span>
            <span @click="ficheOpen = !ficheOpen">
            <b-navbar-dropdown label="Fiches pratiques">
                <router-link to="/fiches" v-show="ficheOpen">
                    <b-navbar-item class="menu-subtitle" >
                        {{ $t('menu.see-all-fp') }}
                    </b-navbar-item>
                </router-link>
                <router-link :to="'/fiche/categorie/'+categorie.id" v-show="ficheOpen"  v-for="categorie in categories" v-bind:key="categorie.id">
                    <b-navbar-item>
                        {{categorie.name}}
                    </b-navbar-item>
                </router-link>
            </b-navbar-dropdown>
            </span>
            <span @click="livreOpen = !livreOpen">
            <b-navbar-dropdown label="Livres blancs">
                <router-link to="/livres"   v-show="livreOpen">
                    <b-navbar-item class="menu-subtitle" >
                        {{ $t('menu.see-all-white-book') }}
                    </b-navbar-item>
                </router-link>
                <router-link :to="'/livre/categorie/'+categorie.id" v-show="livreOpen" v-for="categorie in categories" v-bind:key="categorie.id">
                    <b-navbar-item>
                        {{categorie.name}}
                    </b-navbar-item>
                </router-link>
            </b-navbar-dropdown>
            </span>
        </template>

        <template slot="end">
            <b-navbar-item tag="div">
                <div class="buttons">
                    <router-link to="/account" class="button is-primary" v-if="$store.state.user.loggedIn">
                        {{ $t('menu.account') }}
                    </router-link>
                    <a class="button is-grey"  @click="signout" v-if="$store.state.user.loggedIn">
                        {{ $t('menu.log-out') }}
                    </a>
                </div>
            </b-navbar-item>
        </template>
    </b-navbar>
</span>
        
</template>

<script>
//const fb = require('../../firebaseConfig')
//import EventBus from '../event-bus';
//import algoliasearch from 'algoliasearch/lite';
//const client = algoliasearch('BOZ2ATNMBB', 'f661c7a09a74e550ea676b661ebfd06c');
const fb = require('../../../firebaseConfig')

export default {
  name: 'header',
  components: {
  },
  data (){
    return {
        openMenu:'',
        themes:[],
        categories:[],
        kitOpen:false,
        livreOpen:false,
        ficheOpen:false
    }
  },
    methods:{
      signout(){
        fb.auth.signOut().then(() => {
          window.location.replace('/login')
        });
      }
  },
  created(){
        let here = this
        fb.themesCollection.orderBy('date', 'asc').get().then(function(docs) {
            docs.docs.forEach(element => {
                var temp = element.data()
                temp.id = element.id
                here.themes.push(temp)
            });
        })
        fb.categoriesCollection.orderBy('name', 'asc').get().then(function(docs) {
            docs.docs.forEach(element => {
                var temp = element.data()
                temp.id = element.id
                here.categories.push(temp)
            });
        })
    }
}
</script>
