<template>
<div class="container">
    <h1>
        Politique de confidentialités (RGPD)
    </h1>
        <div>
            <h2>
                Définitions :
            </h2>
            <b>L'Éditeur</b> : La personne, physique ou morale, qui édite les services de communication au public en ligne. 
            <b>Le Site </b> L'ensemble des sites, pages Internet et services en ligne proposés par l'Éditeur.
            <b>L'Utilisateur</b> : La personne utilisant le Site et les services.
        </div>
        <div>
            <h2>
                Nature des données collectées
            </h2>
        
            Dans le cadre de l'utilisation des Sites, l'Éditeur est susceptible de collecter les catégories de données suivantes concernant ses Utilisateurs : 
            <ul>
                <li>Données d'état-civil, d'identité, d'identification... </li>
                <li>Données relatives à la vie professionnelle (Nom de l'entreprise, profession, distinctions...) </li>
                <li>Données de connexion (adresses IP, journaux d'événements...)</li>
            </ul>
        </div>
        <div>
            <h2>
                Communication des données personnelles à des tiers
            </h2>
            <h3>
                Communication aux autorités sur la base des obligations légales
            </h3>
            Sur la base des obligations légales, vos données personnelles pourront être divulguées en application d'une loi, d'un règlement ou en vertu d'une décision d'une autorité réglementaire ou judiciaire compétente. De manière générale, nous nous engageons à nous conformer à toutes les règles légales qui pourraient empêcher, limiter ou réglementer la diffusion d’informations ou de données et notamment à se conformer à la Loi n° 78- 17 du 6 janvier 1978 relative à l'informatique, aux fichiers et aux libertés.

            <h3>
                Communication à des tiers pour sollicitation commerciale pour des produits et services équivalents
            </h3>
            Si vous vous êtes inscrit sur notre Site, nous pouvons, avec nos partenaires commerciaux et de manière occasionnelle, vous tenir informé de nos nouveaux produits, actualités et offres spéciales, par courriel et par téléphone quant à des produits ou services proposés par nous ou nos partenaires.

            <h3>
                Communication à des tiers sous forme agrégée et anonymisée
            </h3>
            Vos données personnelles pourront être utilisées pour enrichir nos bases de données. Elles pourront être transmises à des tiers après avoir été anonymisées et exclusivement à des fins statistiques.

            <h3>
                Communication à des tiers avec restrictions liées aux destinataires
            </h3>
            Les données personnelles que vous nous communiquez lors de votre inscription sont transmises à nos fournisseurs et filiales pour le traitement de celle-ci. Ces informations sont considérées par nos fournisseurs et filiales comme étant strictement confidentielles.

            <h3>
                Communication à des tiers pour marketing direct
            </h3>
            Nous pouvons partager des informations avec des partenaires publicitaires afin de vous envoyer des communications promotionnelles, ou pour vous montrer plus de contenu sur mesure, y compris de la publicité pertinente pour les produits et services susceptibles de vous intéresser, et pour comprendre comment les utilisateurs interagissent avec les publicités.
        </div>
        <div>
            <h2>
                Viralité des conditions de réutilisation des données personnelles
            </h2>
            <h3>
                Engagement sur la viralité des conditions
            </h3>
            En cas de communication de vos données personnelles à un tiers, ce dernier est tenu d'appliquer des conditions de confidentialité identiques à celle du Site.
        </div>
        <div>
            <h2>
                Information préalable pour la communication des données personnelles à des tiers en cas de fusion / absorption
            </h2>
            <h3>Information préalable et possibilité d’opt-out avant et après la fusion / acquisition</h3>  
            Dans le cas où nous prendrions part à une opération de fusion, d’acquisition ou à toute autre forme de cession d’actifs, nous nous engageons à garantir la confidentialité de vos données personnelles et à vous informer avant que celles-ci ne soient transférées ou soumises à de nouvelles règles de confidentialité.
        </div>
        <div>
            <h2>
                Finalité de la réutilisation des données personnelles collectées
            </h2>
            <h3>
                Effectuer les opérations relatives à la gestion des inscrits concernant
            </h3>
            <ul>
                <li>les contrats</li>
                <li>les commandes</li>
                <li>les livraisons</li>
                <li>les factures</li>
                <li>un programme de fidélité au sein d'une entité ou plusieurs entités juridiques</li>
                <li>le suivi de la relation client tel que la réalisation d'enquêtes de satisfaction, la gestion des réclamations et du service après-vente</li>
                <li>la sélection de clients pour réaliser des études, sondages et tests produits (sauf consentement des personnes concernées recueilli dans les conditions prévues à l’article 6, ces opérations ne doivent pas conduire à l'établissement de profils susceptibles de faire apparaître des données sensibles - origines raciales ou ethniques, opinions philosophiques, politiques, syndicales, religieuses, vie sexuelle ou santé des personnes)</li>
            </ul>
            <h3>Effectuer des opérations relatives à la prospection</h3>

            <li>la gestion d'opérations techniques de prospection (ce qui inclut notamment les opérations techniques comme la normalisation, l'enrichissement et la déduplication)</li>
            <li>la sélection de personnes pour réaliser des actions de fidélisation, de prospection, de sondage, de test produit et de promotion. Sauf consentement des personnes concernées recueilli dans les conditions prévues à l'article 6, ces opérations ne doivent pas conduire à l'établissement de profils susceptibles de faire apparaître des données sensibles (origines raciales ou ethniques, opinions philosophiques, politiques, syndicales, religieuses, vie sexuelle ou santé des personnes)</li>
            <li>la réalisation d'opérations de sollicitations</li>  

            <h3>L'élaboration de statistiques commerciales</h3>
            <h3>La cession, la location ou l'échange de ses fichiers de clients et de ses fichiers de prospects</h3>
            <h3>L’actualisation de ses fichiers de prospection par l’organisme en charge de la gestion de la liste d’opposition au démarchage téléphonique, en application des dispositions du code de la consommation La gestion des demandes de droit d'accès, de rectification et d'opposition</h3>
        </div>
        <div>
            <h2>Agrégation des données</h2>
            <h3>Agrégation avec des données non personnelles</h3>
            Nous pouvons publier, divulguer et utiliser les informations agrégées (informations relatives à tous nos Utilisateurs ou à des groupes ou catégories spécifiques d'Utilisateurs que nous combinons de manière à ce qu'un Utilisateur individuel ne puisse plus être identifié ou mentionné) et les informations non personnelles à des fins d'analyse du secteur et du marché, de profilage démographique, à des fins promotionnelles et publicitaires et à d'autres fins commerciales.

            <h3>Agrégation avec des données personnelles disponibles sur les comptes sociaux de l'Utilisateur</h3>
            Si vous connectez votre compte à un compte d’un autre service afin de faire des envois croisés, ledit service pourra nous communiquer vos informations de profil, de connexion, ainsi que toute autre information dont vous avez autorisé la divulgation. Nous pouvons agréger les informations relatives à tous nos autres Utilisateurs, groupes, comptes, aux données personnelles disponibles sur l’Utilisateur.
        </div>
        <div>
            <h2>Collecte des données d'identité</h2>
            <h3>Consultation libre</h3>
            La consultation du Site ne nécessite pas d'inscription ni d'identification préalable. Elle peut s'effectuer sans que vous ne communiquiez de données nominatives vous concernant (nom, prénom, adresse, etc). Nous ne procédons à aucun enregistrement de données nominatives pour la simple consultation du Site.
        </div>
        <div>
            <h2>Collecte des données d'identification</h2>
            <h3>Utilisation de l'identifiant de l'utilisateur pour proposition de mise en relation et offres commerciales</h3>
            Nous utilisons vos identifiants électroniques pour rechercher des relations présentes par connexion, par adresse mail ou par services. Nous pouvons utiliser vos informations de contact pour permettre à d'autres personnes de trouver votre compte, notamment via des services tiers et des applications clientes. Vous pouvez télécharger votre carnet d'adresses afin que nous soyons en mesure de vous aider à trouver des connaissances sur notre réseau ou pour permettre à d'autres Utilisateurs de notre réseau de vous trouver. Nous pouvons vous proposer des suggestions, à vous et à d'autres Utilisateurs du réseau, à partir des contacts importés de votre carnet d’adresses. Nous sommes susceptibles de travailler en partenariat avec des sociétés qui proposent des offres incitatives. Pour prendre en charge ce type de promotion et d'offre incitative, nous sommes susceptibles de partager votre identifiant électronique.
        </div>
        <div>
            <h2>Collecte des données du terminal</h2>
            <h3>Collecte des données de profilage et des données techniques à des fins de fourniture du service</h3>
            Certaines des données techniques de votre appareil sont collectées automatiquement par le Site. Ces informations incluent notamment votre adresse IP, fournisseur d'accès à Internet, configuration matérielle, configuration logicielle, type et langue du navigateur... La collecte de ces données est nécessaire à la fourniture des services.

            <h3>Collecte des données techniques à des fins publicitaires, commerciales et statistiques</h3>
            Les données techniques de votre appareil sont automatiquement collectées et enregistrées par le Site, à des fins publicitaires, commerciales et statistiques. Ces informations nous aident à personnaliser et à améliorer continuellement votre expérience sur notre Site. Nous ne collectons ni ne conservons aucune donnée nominative (nom, prénom, adresse...) éventuellement attachée à une donnée technique. Les données collectées sont susceptibles d’être revendues à des tiers.
        </div>
        <div>
            <h2>Cookies</h2>
            <h3>Durée de conservation des cookies</h3>
            Conformément aux recommandations de la CNIL, la durée maximale de conservation des cookies est de 13 mois au maximum après leur premier dépôt dans le terminal de l'Utilisateur, tout comme la durée de la validité du consentement de l’Utilisateur à l’utilisation de ces cookies. La durée de vie des cookies n’est pas prolongée à chaque visite. Le consentement de l’Utilisateur devra donc être renouvelé à l'issue de ce délai.

            <h3>Finalité cookies</h3>
            Les cookies peuvent être utilisés pour des fins statistiques notamment pour optimiser les services rendus à l'Utilisateur, à partir du traitement des informations concernant la fréquence d'accès, la personnalisation des pages ainsi que les opérations réalisées et les informations consultées. Vous êtes informé que l'Éditeur est susceptible de déposer des cookies sur votre terminal. Le cookie enregistre des informations relatives à la navigation sur le service (les pages que vous avez consultées, la date et l'heure de la consultation...) que nous pourrons lire lors de vos visites ultérieures.

            <h3>Droit de l'Utilisateur de refuser les cookies, la désactivation entraînant un fonctionnement dégradé du service</h3>
            Vous reconnaissez avoir été informé que l'Éditeur peut avoir recours à des cookies, et l'y autorisez. Si vous ne souhaitez pas que des cookies soient utilisés sur votre terminal, la plupart des navigateurs vous permettent de désactiver les cookies en passant par les options de réglage. Toutefois, vous êtes informé que certains services sont susceptibles de ne plus fonctionner correctement.

            <h3>Association possible des cookies avec des données personnelles pour permettre le fonctionnement du service</h3>
            L'Éditeur peut être amenée à recueillir des informations de navigation via l'utilisation de cookies.
        </div>
        <div>
            <h2>Conservation des données techniques</h2>
            <h3>Durée de conservation des données techniques</h3>
            Les données techniques sont conservées pour la durée strictement nécessaire à la réalisation des finalités visées ci-avant.
        </div>
        <div>
            <h2>Délai de conservation des données personnelles et d'anonymisation</h2>
            <h3>Conservation des données pendant la durée de la relation contractuelle</h3>
            Conformément à l'article 6-5° de la loi n°78-17 du 6 janvier 1978 relative à l'informatique, aux fichiers et aux libertés, les données à caractère personnel faisant l'objet d'un traitement ne sont pas conservées au-delà du temps nécessaire à l'exécution des obligations définies lors de la conclusion du contrat ou de la durée prédéfinie de la relation contractuelle.

            <h3>Conservation des données anonymisées au delà de la relation contractuelle.</h3>
            Nous conservons les données personnelles pour une durée de 10ans. Au-delà de cette durée, elles seront anonymisées et conservées à des fins exclusivement statistiques et ne donneront lieu à aucune exploitation, de quelque nature que ce soit.
        </div>
        <div>
            <h2>Suppression du compte</h2>
            <h3>Suppression du compte à la demande</h3> 
            L'Utilisateur a la possibilité de supprimer son Compte à tout moment, par simple demande à l'Éditeur par email à hello@wavy.co

            <h3>Suppression du compte en cas de violation des CGU</h3>
            En cas de violation d'une ou de plusieurs dispositions des CGU ou de tout autre document incorporé aux présentes par référence, l'Éditeur se réserve le droit de mettre fin ou restreindre sans aucun avertissement préalable et à sa seule discrétion, votre usage et accès aux services, à votre compte et à tous les Sites.
        </div>
        <div>
            <h2>Indications en cas de faille de sécurité décelée par l'Éditeur</h2>
            <h3>Information de l'Utilisateur en cas de faille de sécurité</h3>
            Nous nous engageons à mettre en oeuvre toutes les mesures techniques et organisationnelles appropriées afin de garantir un niveau de sécurité adapté au regard des risques d'accès accidentels, non autorisés ou illégaux, de divulgation, d'altération, de perte ou encore de destruction des données personnelles vous concernant. Dans l'éventualité où nous prendrions connaissance d'un accès illégal aux données personnelles vous concernant stockées sur nos serveurs ou ceux de nos prestataires, ou d'un accès non autorisé ayant pour conséquence la réalisation des risques identifiés ci-dessus, nous nous engageons à :

            <ul>
                <li>Vous notifier l'incident dans les plus brefs délais</li>
                <li>Examiner les causes de l'incident et vous en informer</li>
                <li>Prendre les mesures nécessaires dans la limite du raisonnable afin d'amoindrir les effets négatifs et préjudices pouvant résulter dudit incident</li>
            </ul>
            
            <h3>Limitation de la responsabilité</h3>
            En aucun cas les engagements définis au point ci-dessus relatifs à la notification en cas de faille de sécurité ne peuvent être assimilés à une quelconque reconnaissance de faute ou de responsabilité quant à la survenance de l'incident en question.
        </div>
        <div>
            <h2>Transfert des données personnelles à l'étranger
            </h2>
            <h3>Pas de transfert en dehors de l'Union européenne</h3>
            L'Éditeur s'engage à ne pas transférer les données personnelles de ses Utilisateurs en dehors de l'Union européenne.
        </div>
        <div>
           <h2>Modification des CGU et de la politique de confidentialité</h2>
            <h3>En cas de modification des présentes CGU, engagement de ne pas baisser le niveau de confidentialité de manière substantielle sans l'information préalable des personnes concernées</h3>
            Nous nous engageons à vous informer en cas de modification substantielle des présentes CGU, et à ne pas baisser le niveau de confidentialité de vos données de manière substantielle sans vous en informer et obtenir votre consentement.
        </div>
        <div>
            <h2>Droit applicable et modalités de recours</h2>
            <h3>Application du droit français (législation CNIL) et compétence des tribunaux</h3>       
            Les présentes CGU et votre utilisation du Site sont régies et interprétées conformément aux lois de France, et notamment à la Loi n° 78-17 du 6 janvier 1978 relative à l'informatique, aux fichiers et aux libertés. Le choix de la loi applicable ne porte pas atteinte à vos droits en tant que consommateur conformément à la loi applicable de votre lieu de résidence. Si vous êtes un consommateur, vous et nous acceptons de se soumettre à la compétence non-exclusive des juridictions françaises, ce qui signifie que vous pouvez engager une action relative aux présentes CGU en France ou dans le pays de l'UE dans lequel vous vivez. Si vous êtes un professionnel, toutes les actions à notre encontre doivent être engagées devant une juridiction en France. En cas de litige, les parties chercheront une solution amiable avant toute action judiciaire. En cas d'échec de ces tentatives, toutes contestations à la validité, l'interprétation et / ou l'exécution des présentes CGU devront être portées même en cas de pluralité des défendeurs ou d'appel en garantie, devant les tribunaux français.
        </div>
        <div>
            <h2>Portabilité des données</h2>
            <h3>Portabilité des données</h3>
          L'Éditeur s'engage à vous offrir la possibilité de vous faire restituer l'ensemble des données vous concernant sur simple demande par email à hello@wavy.co. L'Utilisateur se voit ainsi garantir une meilleure maîtrise de ses données, et garde la possibilité de les réutiliser. Ces données devront être fournies dans un format ouvert et aisément réutilisable.
        </div>
</div>
</template>
<style scoped>
  div > h2 {
      font-size:20px;
      font-weight:700;
      margin-top:10px;
  }
  div > h3 {
      font-size:16px;
      font-weight:600;
      margin-top:5px;
  }
  div{
      margin-top:20px
  }

  div > h1{
      font-size:25px;
      font-weight:900;
  }

</style>

<script>

export default {
    name: 'NotFound',
    data (){
        return {
           
        }
    },
    components:{

    },
    computed: {
        
    },
    created(){
        
    },
    methods:{
    },
    props: {

    }
}
</script>

