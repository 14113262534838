<template>
<div class="container">
    <div class="columns is-multiline is-centered">
        <div class="column is-6" style="text-align:center" >
            <h1>
               {{$t('menu.not-found')}}
            </h1>
        </div>
    </div>
     <div class="columns is-multiline is-centered">
        <div class="column is-6" style="text-align:center" >
            <img src="https://res.cloudinary.com/wavy/image/upload/v1601985641/wavy-home/Wavy%20Ressources/undraw_page_not_found_su7k.png" >
        </div>
     </div>
</div>
</template>

<script>

export default {
    name: 'NotFound',
    data (){
        return {
           
        }
    },
    components:{

    },
    computed: {
        
    },
    created(){
        
    },
    methods:{
    },
    props: {

    }
}
</script>

