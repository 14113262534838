<template>
    <div class="card">
      <div class="card-image">
        <figure class="image is-2by1">
          <img :src="content.thumbnail" alt="Placeholder image">
        </figure>
      </div>
      <div class="card-content">
        <div class="label" style="text-align:left">
            <b-tag type="is-primary" v-if="content.type == 'fp'">{{ $t('menu.fp') }}</b-tag>
            <b-tag type="is-primary" v-else-if="content.type == 'lb'">{{ $t('menu.white-book') }}</b-tag>
            <b-tag style="margin-left:5px" type="is-grey">{{content.tag}}</b-tag>
        </div>
        <div class="content" style="text-align:left">
          <p class="title">
           {{content.title}}
          </p>
          {{content.description}}
          <br>
        </div>
        <div class="columns">
          <div class="column is-12" >
            <a target="_blank" @click="downloadFiche" v-if="authorization == 1"><b-button type="is-primary" class="card-button" > {{ $t('buttons.download') }}</b-button></a>
            <div v-if="authorization == 2" class="columns is-centered">
              <div class="column is-12 has-text-centered" >
                {{ $t('content.block-customers') }}
              </div>
             </div>
            <a href="https://wavy.co?utm_source=wavy-ressources&utm_medium=blocked-content" v-if="authorization == 2"><b-button type="is-primary" class="card-button" >{{ $t('buttons.discover-wavy') }}</b-button></a>
             <div v-if="authorization == 3" class="columns is-centered">
              <div class="column is-12 has-text-centered" @click="redirect(content.slug)">
                {{ $t('content.block-signedin') }}
              </div>
             </div>
            <a :href="'/account'" v-if="authorization == 3"><b-button type="is-primary" class="card-button" >{{ $t('buttons.finalize') }}</b-button></a>
            <router-link v-if="$store.state.user.data.email.includes('@wavy.co')" :to="'/admin/content/edit/'+content.slug" ><b-button type="is-grey" class="card-button" >{{ $t('buttons.edit') }}</b-button></router-link>
            <a  :href="'https://www.facebook.com/sharer/sharer.php?u=https://content.wavy.co/fiche/'+content.slug" ><b-button type="is-grey" class="card-button" >{{ $t('buttons.share') }}</b-button></a>
          </div>
        </div>
        <div class="columns" v-if="content.link_video">
          <div class="column is-12">
          <a  :href="content.link_video"><b-button type="is-grey" class="card-button" >{{ $t('content.see-video') }}</b-button></a>
          </div>
        </div>
      </div>
      <footer class="card-footer">
        
      </footer>
    </div>
</template>

<script>
//const fb = require('../../firebaseConfig')
//import EventBus from '../event-bus';

export default {
  name: 'card_fiche',
  components: {
  },

props:{
  content:Object
},
methods:{
    redirect(slug){
    window.localStorage.setItem('route', `fiche/${slug}` )
    this.$router.push({ path: `/account/` })
  },
    downloadFiche(){
      window.userengage("event.[Cold]_Fiches",{
        "Value": this.content.slug,
        "Type": this.content.type
      });
      window.open(this.content.url, "_blank")
    }
},
computed: {
        authorization:function(){
            if(this.$store.state.user.status == 'gold'){
              return 1
            } else if( this.content.authorization == 'gold')
            {
              if(this.$store.state.user.status == 'silver' || this.$store.state.user.status == 'bronze'){
                return 2
              }
            } 
            else if(this.content.authorization == 'silver')
            {
              if(this.$store.state.user.status == 'silver'){
                return 1
              } else if(this.$store.state.user.status == 'bronze'){
                return 3
              }
            } else if( this.content.authorization == 'bronze')
            {
              return 1
            }
            return 3
                  }
    },
created(){

}
}
</script>
