<template>
  <div>
      <h1 class="home-title">
        {{$t('content.last')}}
      </h1>
      <LoadingCard v-if="!isLoaded"></LoadingCard>

      <div class="columns is-multiline is-centered">
        <div v-for="content in contents" v-show="content.type == 'fp' || content.type == 'kit' || content.type == 'magazine'" v-bind:key="content.id" class="column is-4">
          <CardKit v-if="content.type == 'kit'"  :content="content" :lang="$i18n.locale"></CardKit>
          <CardFiche v-if="content.type=='fp'" :content="content" :lang="$i18n.locale"></CardFiche>
          <CardMagazine v-if="content.type=='magazine'" :content="content" :lang="$i18n.locale"></CardMagazine>
        </div>
      </div>
  </div>
</template>

<script>
import CardKit from '../blocs/card_kit'
import CardFiche from '../blocs/card_fiche'
import LoadingCard from '../blocs/loading_card'
import CardMagazine from '../blocs/card_magazine'

const fb = require('../../../firebaseConfig')


export default {
  name: 'Home',
  components:{
      CardKit,
      CardFiche,
      LoadingCard,
      CardMagazine
    },
   data (){
        return {
            contents:[],
            isLoaded:false
        }
    },
  props: {

  },
  created(){
    this.getContent()
  },
  computed:{
    lang(){
      return this.$attrs.lang
    }
  },
  watch:{
      lang:function(){
          this.contents = []
          this.isLoaded = false
          this.getContent()
      }
  },
  methods:{
    getContent:function(){
      let here = this
        fb.contentsCollection.orderBy('updatedAt', 'desc').get().then(function(docs) {
          here.isLoaded = true
          docs.forEach(doc => {
              var contentTemp = doc.data()
              contentTemp.id = doc.id
              if((contentTemp.language == here.$i18n.locale) || (!contentTemp.language && here.$i18n.locale == 'fr' )){
                here.contents.push(contentTemp)
              }
          });
        })
    }
  }
}
</script>

