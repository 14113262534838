<template>
  <div>
    <div class="container" style="margin-bottom:50px;">
      <h2 class="home-title">
        {{content.title}} 
      </h2>
      <div class="columns">
         <div class="column is-5">
          <b-skeleton width="90%" height="100px" :animated="true" v-if="!isMainLoaded"></b-skeleton>
           <img :src="content.thumbnail" style="width:100%">

           
        </div>
        <div class="column is-7">
          <div class="columns">
            <div class="column is-12">
              <b-skeleton width="20%" :animated="true" v-if="!isMainLoaded"></b-skeleton>
               <b-tag type="is-primary" v-if="isMainLoaded">{{$t('menu.kit')}}</b-tag>
            </div>
          </div>
           <div class="columns">
            <div class="column is-6">              
                <p class="result_description">
                  <b-skeleton width="50%" :animated="true" v-if="!isMainLoaded"></b-skeleton>
                  <b-skeleton width="50%" :animated="true" v-if="!isMainLoaded"></b-skeleton>
                  {{content.description}} 
                </p>
            </div>
          </div>
          <div class="columns">
            <div class="column is-6">
               <b-skeleton width="80%" height="40px" :animated="true" v-if="!isMainLoaded"></b-skeleton>
                <a @click="downloadKit" v-if="isMainLoaded && authorization == 1"><b-button type="is-primary" class="card-button" >{{$t('buttons.download')}}</b-button></a>
                <div v-if="isMainLoaded && authorization == 2" class="columns is-centered">
                  <div class="column is-12 has-text-centered">
                    {{$t('content.block-customers')}}
                  </div>
                </div>
                <a href="https://wavy.co?utm_source=wavy-ressources&utm_medium=blocked-content" v-if="isMainLoaded && authorization == 2"><b-button type="is-primary" class="card-button" >{{$t('buttons.discover-wavy')}}</b-button></a>
                <div v-if="isMainLoaded && authorization == 3" class="columns is-centered">
                  <div class="column is-12 has-text-centered" >
                    {{$t('content.finalize')}}
                  </div>
                </div>
                <a @click="redirect(content.slug)" v-if="isMainLoaded && authorization == 3"><b-button type="is-primary" class="card-button" >{{$t('buttons.finalize')}}</b-button></a>
           
            </div>
          </div>
          
        </div>
      </div>
    </div>
    
    <div class="container" v-if="authorization == 1">
      <h2 class="home-title">
        Tous les visuels à poster sur vos réseaux
      </h2>
      
        <LoadingCard v-if="!isLoaded"></LoadingCard>
        <div class="columns is-multiline is-centered">

        <div class="column is-3" v-for="element in computedDocs" v-bind:key="element.title">
          <CardVisuel :title="content.theme" :content="element" ></CardVisuel>
        </div>
       </div>
       <div class="columns is-centered">
         <div class="column is-4">
           <b-button type="is-grey" class="card-button" v-if="isLoaded && limit < link_contents.length" @click="limit = limit+8" >Voir plus</b-button>
         </div>
       </div>
    </div>
  </div>
</template>

<script>
import CardVisuel from '../blocs/card_visuel'
const fb = require('../../../firebaseConfig')
import LoadingCard from '../blocs/loading_card'

export default {
  name: 'Kit',
  components:{
      CardVisuel,
      LoadingCard,
    },
     data (){
        return {
            content:{},
            link_contents:[],
            isLoaded:false,
            isMainLoaded:false,
            limit:8,
            authorization:2
        }
    },
  props: {

  },
  methods:{
     redirect(slug){
      window.localStorage.setItem('route', `fiche/${slug}` )
      this.$router.push({ path: `/account/` })
    },
    downloadKit(){
      window.userengage("event.[Cold]_Kit",{
        "Value": this.content.slug,
        "Type": this.content.type
      })
      window.open(this.content.url, "_blank")

    }
  },
  created(){
        let here = this
        fb.contentsCollection.where("slug", "==", here.$route.params.slug).get().then(function(docs) {
            here.isMainLoaded = true
            
            if(here.content.type == 'lb'){
                window.location.replace('/livre/'+here.content.slug)
              }
              else if(here.content.type == 'fp'){
                window.location.replace('/fiche/'+here.content.slug)
              }
              else if(here.content.type == 'tool'){
                window.location.replace('/tool/'+here.content.slug)
              }
              else if(here.content.type == 'visuel'){
                window.location.replace('/')
              }
            here.content =  docs.docs[0].data()
            here.content.id = docs.docs[0].id
            if(here.$store.state.user.status == 'gold'){
                  here.authorization = 1
                } else if( here.content.authorization == 'gold')
                {
                  if(here.$store.state.user.status == 'silver' || here.$store.state.user.status == 'bronze'){
                    here.authorization = 2
                  }
                } 
                else if(here.content.authorization == 'silver')
                {
                  if(here.$store.state.user.status == 'silver'){
                    here.authorization = 1
                  } else if(here.$store.state.user.status == 'bronze'){
                    here.authorization = 3
                  }
                } else if( here.content.authorization == 'bronze')
                {
                  here.authorization = 1
                }
            fb.visuelsCollection.where("link_contents", "array-contains", here.content.id).get().then(function(docs_visuels) {
              here.isLoaded = true
              docs_visuels.forEach(function (doc) {
                here.link_contents.push(doc.data())
              });
            })
        })
        
},
computed:{
  computedDocs(){
    return this.limit ? this.link_contents.slice(0,this.limit) : this.link_contents
  }
}
}
</script>

