<template>
  <div id="app">
      
      <HeaderMobile></HeaderMobile>
      <HeaderNav ></HeaderNav>
      <cookie-law :buttonText="'J\'accepte'" :theme="'mytheme'">
        <div slot="message">
          🍪  Ce site web utilise des cookies ! 🍪<br>
          <p style="font-size:13px">Les cookies nous permettent de nous assurer le bon fonctionnement de Wavy Content, d'analyser notre trafic et de personnaliser le contenu et les annonces. Nous partageons également des informations sur l'utilisation de notre site avec nos partenaires de médias sociaux, de publicité et d'analyse, qui peuvent combiner celles-ci avec d'autres informations que vous leur avez fournies ou qu'ils ont collectées lors de votre utilisation de leurs services
          En savoir plus sur <router-link to="/rgpd">notre politique de confidentialité </router-link><br>
          En continuant votre navigation, vous accepter l'utilisation de cookies. </p>
        </div>
      </cookie-law>
      <div class="columns is-centered">
        <div class="column is-2" v-if="$route.path != '/login'">
          <Menu :lang="$i18n.locale" ></Menu>
        </div>
        <div class="column is-10">
          <div class="container">
            <div class="column is-12 padding-app" >
                <router-view :lang="$i18n.locale" :key="$route.fullPath"></router-view>
            </div>
          </div>
        </div>
      </div>
  </div>
</template>

<script>
import HeaderNav from './components/blocs/header'
import Menu from './components/blocs/menu'
import HeaderMobile from './components/blocs/header_mobile'
import CookieLaw from 'vue-cookie-law'

import { mapState } from "vuex";
const md5 = require('md5');
const axios = require('axios');

export default {
  name: 'App',
  components:{
      HeaderNav,
      Menu,
      HeaderMobile,
      CookieLaw
    },
  data(){
    return {
      UEReady:false
    }
  },
  
  mounted () {
    
    let here = this    
    axios.get(`https://ipapi.co/json`).then(response => {
      //here.fb.firebase.auth.languageCode = response.data.country.toLowerCase();
      if(response.data.country.toLowerCase() == "es"){
          here.$i18n.locale = response.data.country.toLowerCase()
      } else if(response.data.country.toLowerCase() == "en"){
         here.$i18n.locale = response.data.country.toLowerCase()
      }
    })
    },
  computed: {
    ...mapState(['user']),
  },
  created() {
      let addThisScript = document.createElement('script')
      addThisScript.setAttribute('src', '//s7.addthis.com/js/300/addthis_widget.js#pubid=ra-5f85941a0b5422ef')
      addThisScript.setAttribute('type', "text/javascript")
      document.head.appendChild(addThisScript) 
      let userScript = document.createElement('script')
      userScript.setAttribute('src', 'https://wavy.user.com/widget.js')
      userScript.setAttribute('data-cfasync', false)
      document.head.appendChild(userScript)
      
      window.civchat = {
            apiKey: "kA9jGs"
        };
      let here = this
      this.checkingInterval = setInterval(function(){
        if (window.UE) {
          here.UEReady = true
        }
      }, 500)

      
    
  },

  watch:{
    $route(){
      if(this.$store.state.user && this.$store.state.user.data && this.$store.state.user.data.email){
       window.UE.pageHit({
        apiKey: "kA9jGs",
        user_id: md5(this.$store.state.user.data.email),
        email:this.$store.state.user.data.email
        })
      } else {
          window.UE.pageHit({
          apiKey: "kA9jGs",
          })
      }
    },
    

    UEReady:function (data){
      if (data) { 
       clearInterval(this.checkingInterval) 
       if(this.$store.state.user && this.$store.state.user.data && this.$store.state.user.data.email){
        window.UE.pageHit({
          apiKey: "kA9jGs",
          user_id: md5(this.$store.state.user.data.email),
          email:this.$store.state.user.data.email
          })
        } else {
          window.UE.pageHit({
          apiKey: "kA9jGs",
          })
      }
      } 
    }
  } 
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.Cookie--mytheme {
  background-color:#FFF;
  padding:20px;
  padding-left: 100px;
  z-index:1000000
}
.Cookie--mytheme .Cookie__button {
    background-color: #254873;
    border-color: transparent;
    color: #fff;
    border-radius:5px;
    width:100%;
    padding:10px;
   

}
.Cookie--mytheme .Cookie__buttons{
 min-width: 20%;
}
@media only screen and (max-width: 768px) {
  .Cookie--mytheme {
  background-color:#FFF;
  padding:20px;
  padding-left: 20px;
  height:100%;
}
}

</style>
