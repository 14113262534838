import firebase from 'firebase/app'
import 'firebase/firestore'
import 'firebase/auth'

// firebase init goes here
const config = {
    apiKey: "AIzaSyDuKX8iOQxqNhy7aWwKyEAvbNvlwg0uD7o",
    authDomain: "wavy-ressources.firebaseapp.com",
    databaseURL: "https://wavy-ressources.firebaseio.com",
    projectId: "wavy-ressources",
    storageBucket: "wavy-ressources.appspot.com",
    messagingSenderId: "1058348103597",
    appId: "1:1058348103597:web:87f72b7b91e2368a5fd324",
    measurementId: "G-JFLYJ5424P"
}
firebase.initializeApp(config)

// firebase utils
const db = firebase.firestore()
const auth = firebase.auth()
const currentUser = auth.currentUser
firebase.auth().setPersistence(firebase.auth.Auth.Persistence.LOCAL)

// date issue fix according to firebase
const settings = {
}
db.settings(settings)
// firebase collections
const usersCollection = db.collection('users')
const contentsCollection = db.collection('contents')
const themesCollection = db.collection('themes')
const categoriesCollection = db.collection('categories')
const visuelsCollection = db.collection('visuels')

export {
    db,
    auth,
    currentUser,
    usersCollection,
    contentsCollection,
    themesCollection,
    categoriesCollection,
    visuelsCollection,
    firebase
}