<template>
  <div>
    <div class="container" style="margin-bottom:50px;">
      <h2 class="home-title">
        {{content.title}} 
      </h2>
      <div class="columns">
         <div class="column is-5">
           <img :src="content.thumbnail" style="width:100%">
        </div>
        <div class="column is-7">
          <div class="columns">
            <div class="column is-12">
              <b-tag type="is-primary" v-if="content.type == 'fp'">{{$t('menu.fp')}}</b-tag>
            <b-tag type="is-primary" v-else-if="content.type == 'lb'">{{$t('menu.white-book')}}</b-tag>
            </div>
          </div>
           <div class="columns">
            <div class="column is-6">              
                <p class="result_description">
                  {{content.description}} 
                </p>
            </div>
          </div>
          <div class="columns">
            <div v-if="authorization != 0" class="column is-6">
                <a @click="downloadFiche"><b-button type="is-primary" class="card-button" >{{$t('buttons.download')}}</b-button></a>
            </div>
            <div v-else class="column is-6">
                <a @click="redirectoLogin"><b-button type="is-primary" class="card-button" >{{$t('buttons.logintodownload')}}</b-button></a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="container" style="margin-top:100px;">
       <h3 class="home-subtitle">
       {{$t('content.other-content')}}
      </h3>
      <div class="columns multiline">
          <div v-for="content in link_contents" v-show="content.type == 'fp' || content.type == 'kit'" v-bind:key="content.id" class="column is-4">
            <CardKit v-if="content.type == 'kit'" :content="content" ></CardKit>
            <CardFiche v-if="content.type=='fp'" :content="content" ></CardFiche>
            
          </div>
        </div>
      </div>
  </div>
</template>

<script>
const fb = require('../../../firebaseConfig')
import CardKit from '../blocs/card_kit'
import CardFiche from '../blocs/card_fiche'

export default {
  name: 'ficheDetails',
  data (){
        return {
            content:{},
            link_contents:[]
        }
    },
  components:{
      CardKit,
      CardFiche
    },
  props: {

  },
  methods:{
    downloadFiche(){
      window.userengage("event.[Cold]_Fiches",{
        "Value": this.content.slug,
        "Type": this.content.type
      });
      window.open(this.content.url, "_blank")
    },
    redirectoLogin(){
      window.localStorage.setItem('route', `/livre/${this.content.slug}`)
      this.$router.push("/login")
      
    }
  },
  computed:{
            authorization:function(){
              if(this.$store.state.user.loggedIn){
            if(this.$store.state.user.status == 'gold'){
              return 1
            } else if( this.content.authorization == 'gold')
            {
              if(this.$store.state.user.status == 'silver' || this.$store.state.user.status == 'bronze'){
                return 2
              }
            } 
            else if(this.content.authorization == 'silver')
            {
              if(this.$store.state.user.status == 'silver'){
                return 1
              } else if(this.$store.state.user.status == 'bronze'){
                return 3
              }
            } else if( this.content.authorization == 'bronze')
            {
              return 1
            }
            return 3
                  } else {
                    return 0
                  }
            }
  },
  created(){
        let here = this
        fb.contentsCollection.where("slug", "==", here.$route.params.slug).get().then(function(docs) {
            here.content =  docs.docs[0].data()
              if(here.content.type == 'kit'){
                window.location.replace('/kit/'+here.content.slug)
              }
              else if(here.content.type == 'fp'){
                window.location.replace('/fiche/'+here.content.slug)
              }
              else if(here.content.type == 'tool'){
                window.location.replace('/tool/'+here.content.slug)
              }
              else if(here.content.type == 'visuel'){
                window.location.replace('/')
              }

            here.content.link_contents.forEach(element => {
              fb.contentsCollection.doc(element).get().then(function(doc) {
                here.link_contents.push(doc.data())
              });
            })
        })
}
}
</script>

