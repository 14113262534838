import Vue from "vue";
import Router from "vue-router";
import Home from "./components/pages/Home";
import Kit from "./components/pages/Kit";
import Video from "./components/pages/Video";
import Magazine from "./components/pages/Magazine";
import Fiche from "./components/pages/Fiche";
import Login from "./components/pages/Login";
import LoggedIn from "./components/pages/LoggedIn";
import addContent from "./components/admin/addContent";
import addCategories from "./components/admin/addCategoriesAndTheme";
import LivreDetails from "./components/pages/LivreDetails";
import Livre from "./components/pages/Livre";
import NotFound from "./components/pages/NotFound";
import ficheDetails from "./components/pages/FicheDetails";
import videoDetails from "./components/pages/VideoDetails";
import kitDetails from "./components/pages/KitDetails";
import magazineDetails from "./components/pages/MagazineDetails";
import editContent from "./components/admin/editContent";
import account from "./components/pages/Account";
import { store } from './store.js'
import Confidentialite from "./components/pages/Confidentialite";
import Mentions from "./components/pages/Mentions";

Vue.use(Router);

const router = new Router({
  mode: 'history',
  routes: [
    {
      path: "/",
      name: "Home",
      components: {default: Home},
      meta: {
        requiresAuth: true
      }
    },
    {
      path: "/account",
      name: "Account",
      components: {default: account},
      meta: {
        requiresAuth: true
      }
    },
    {
      path: "/kits",
      name: "Kit",
      components: {default: Kit},
      meta: {
        requiresAuth: true
      }
    },
    {
      path: "/magazines",
      name: "Magazine",
      components: {default: Magazine},
      meta: {
        requiresAuth: true
      }
    },
    {
      path: "/videos",
      name: "Video",
      components: {default: Video},
      meta: {
        requiresAuth: true
      }
    },
    {
      path: "/kit/theme/:slug",
      name: "KitTheme",
      components: {default: Kit},
      meta: {
        requiresAuth: true
      }
    },
    {
      path: "/fiches",
      name: "Fiche",
      components: {default: Fiche},
      meta: {
        requiresAuth: true
      }
    },
    {
      path: "/login",
      name: "Login",
      components: {default: Login}
    },
    {
      path: "/login/finalize",
      name: "LoggedIn",
      components: {default: LoggedIn}
    },
    {
      path: "/admin/content/add",
      name: "addContent",
      components: {default: addContent},
      meta: {
        requiresAuth: true
      }
    },
    {
      path: "/admin/content/edit/:slug",
      name: "editContent",
      components: {default: editContent},
      meta: {
        requiresAuth: true
      }
    },
    {
      path: "/admin/categories/add/",
      name: "addCategories",
      components: {default: addCategories},
      meta: {
        requiresAuth: true
      }
    },
    {
      path: "/fiche/:slug",
      name: "FicheDetail",
      components: {default: ficheDetails},
      meta: {
        requiresAuth: true
      }
    },
    {
      path: "/magazine/:slug",
      name: "MagazineDetail",
      components: {default: magazineDetails},
      meta: {
        requiresAuth: true
      }
    },
    {
      path: "/video/:slug",
      name: "VideoDetail",
      components: {default: videoDetails},
      meta: {
        requiresAuth: true
      }
    },
    {
      path: "/fiche/categorie/:slug",
      name: "FicheCategorie",
      components: {default: Fiche},
      meta: {
        requiresAuth: true
      }
    },
    {
      path: "/video/categorie/:slug",
      name: "VideoCategorie",
      components: {default: Video},
      meta: {
        requiresAuth: true
      }
    },
    {
      path: "/livres",
      name: "Livre",
      components: {default: Livre},
      meta: {
        requiresAuth: true
      }
    },
    {
      path: "/livre/:slug",
      name: "LivreDetail",
      components: {default: LivreDetails},
      meta: {
        requiresAuth: false
      }
    },
    {
      path: "/livre/categorie/:slug",
      name: "LivreCategorie",
      components: {default: Livre},
      meta: {
        requiresAuth: true
      }
    },
    {
      path: "/kit/:slug",
      name: "FicheDetail",
      components: {default: kitDetails},
      meta: {
        requiresAuth: true
      }
    },
    { 
      path: "*",
      name: "404",
      components: {default: NotFound},
      meta: {
        requiresAuth: false
      }
     },
     {
      path: "/rgpd",
      name: "RGPD",
      components: {default: Confidentialite},
      meta: {
        requiresAuth: false
      }
    },
    {
      path: "/mentions-legales",
      name: "Mentions",
      components: {default: Mentions},
      meta: {
        requiresAuth: false
      }
    }
  ]
});

router.beforeEach((to, from, next) => {
  const requiresAuth = to.matched.some(x => x.meta.requiresAuth)
  const currentUser = store.state.user.loggedIn
  if (requiresAuth && !currentUser) {
      localStorage.route = to.path;
      next('/login')
  } else if (requiresAuth && currentUser) {
      next()
  } else {
      if((to.path == '/login') && currentUser){
        next('/')
      }
       else if(to.path == '/login/finalize' && currentUser){
        next('/')
       }
      next()
  }
})
export default router;
