<template>
    <div>
        <div class="menu">
            <div class="menu-button" @click="openMenu = 'kit'">
                <div class="menu-title" >
                    {{ $t('menu.kit') }}
                </div>
                <span class="menu-subtitles-group" v-if="openMenu == 'kit'">
                    <router-link to="/kits" >
                        <div class="menu-subtitle" >
                            {{ $t('menu.see-all-kit') }}
                        </div>
                    </router-link>
                    <router-link :to="'/kit/theme/'+theme.id" v-for="theme in themes" v-bind:key="theme.id">
                        <div class="menu-subtitle" >
                            {{theme.name}}
                        </div>
                    </router-link>
                </span>
            </div>
            <div class="menu-button"  @click="openMenu = 'fp'">
                <div class="menu-title">
                    {{ $t('menu.fp') }}
                </div>
                <span class="menu-subtitles-group" v-if="openMenu == 'fp'">
                    <router-link to="/fiches" >
                        <div class="menu-subtitle" >
                            {{ $t('menu.see-all-fp') }}
                        </div>
                    </router-link>
                    <router-link :to="'/fiche/categorie/'+categorie.id"  v-for="categorie in categories" v-bind:key="categorie.id">
                        <div class="menu-subtitle" >
                            {{categorie.name}}
                        </div>
                    </router-link>
                </span>
            </div>
            <div class="menu-button"  @click="openMenu = 'lb'">
                <div class="menu-title">
                    {{ $t('menu.white-book') }}
                </div>
                <span class="menu-subtitles-group" v-if="openMenu == 'lb'">
                    <router-link to="/livres" >
                        <div class="menu-subtitle" >
                           {{ $t('menu.see-all-white-book') }}
                        </div>
                    </router-link>
                    <router-link :to="'/livre/categorie/'+categorie.id"  v-for="categorie in categories" v-bind:key="categorie.id">
                        <div class="menu-subtitle" >
                            {{categorie.name}}
                        </div>
                    </router-link>
                </span>
            </div>
            <div class="menu-button"  @click="openMenu = 'video'">
                <div class="menu-title">
                    {{ $t('menu.video') }}
                </div>
                <span class="menu-subtitles-group" v-if="openMenu == 'video'">
                    <router-link to="/videos" >
                        <div class="menu-subtitle" >
                           {{ $t('menu.see-all-video') }}
                        </div>
                    </router-link>
                    <router-link :to="'/video/categorie/'+categorie.id"  v-for="categorie in categories" v-bind:key="categorie.id">
                        <div class="menu-subtitle" >
                            {{categorie.name}}
                        </div>
                    </router-link>
                </span>
            </div>
            <div class="menu-button">
                <div class="menu-title">
                    <router-link to="/magazines" >
                    {{ $t('menu.magazine') }}
                    </router-link>
                </div>
            </div>
            <div class="menu-button" v-if="false">
                <div class="menu-title">
                    {{ $t('menu.my-downloads') }}
                </div>
            </div>
            <div class="menu-button" v-if="$store.state.user.loggedIn">
            
                <div class="menu-title">
                    <router-link to="/account" >
                    {{ $t('menu.account') }}
                    </router-link>
                </div>
            
            </div>
            <div class="menu-button" @click="signout" v-if="$store.state.user.loggedIn">
                <div class="menu-title">
                    {{ $t('menu.log-out') }}
                </div>
            </div>
        </div>
    </div>
</template>

<script>
//import EventBus from '../event-bus';
const fb = require('../../../firebaseConfig')

export default {
  name: 'header',
  components: {
  },
  data (){
    return {
        openMenu:'',
        themes:[],
        categories:[]
    }
  },
  methods:{
      signout(){
        fb.auth.signOut().then(() => {
          window.location.replace('/login')
        });
      },
       getThemesAndCategories:function(){
          let here = this
        fb.themesCollection.orderBy('date', 'asc').get().then(function(docs) {
            docs.docs.forEach(element => {
                var temp = element.data()
                temp.id = element.id
                if(temp.language == here.$i18n.locale){
                    here.themes.push(temp)
                }
            });
        })
        fb.categoriesCollection.orderBy('name', 'asc').get().then(function(docs) {
            docs.docs.forEach(element => {
                var temp = element.data()
                temp.id = element.id
                if(temp.language == here.$i18n.locale){
                    here.categories.push(temp)
                }
                
            });
        })
        }
  },

  watch:{
      lang:function(){
          this.themes = []
          this.categories = []
          this.getThemesAndCategories()

      }
  },
  created(){
        this.themes = []
        this.categories = []
        this.getThemesAndCategories()
    },
    props:{
        lang:String
    }
}
</script>
