<template>
    <div class="card">
      <div class="card-image">
        <figure class="image is-2by1">
          <img :src="content.thumbnail" alt="Placeholder image">
        </figure>
      </div>
      <div class="card-content">
        <div class="label" style="text-align:left">
            <b-tag type="is-primary">{{ $t('menu.video') }}</b-tag>
            <b-tag v-if="content.theme && content.theme != ''" type="is-grey" style="margin-left:10px;">{{content.theme}}</b-tag>
        </div>
        <div class="content" style="text-align:left">
          {{content.description}}
          <br>
        </div>
        <div class="columns">
          <div class="column is-12">
            <router-link v-if="authorization == 1" :to="'/video/'+content.slug"><b-button type="is-primary" class="card-button" >{{ $t('buttons.see') }}</b-button></router-link>
            <div v-if="authorization == 2" class="columns is-centered">
              <div class="column is-12 has-text-centered">
                 {{ $t('content.block-customers') }}
              </div>
             </div>
            <a href="https://wavy.co?utm_source=wavy-ressources&utm_medium=blocked-content" v-if="authorization == 2"><b-button type="is-primary" class="card-button" >{{ $t('buttons.discover-wavy') }}</b-button></a>
             <div v-if="authorization == 3" class="columns is-centered">
              <div class="column is-12 has-text-centered" >
                 {{ $t('content.finalize') }}
              </div>
             </div>
            <a @click="redirect(content.slug)" v-if="authorization == 3"><b-button type="is-primary" class="card-button" > {{ $t('buttons.finalize') }}</b-button></a>
            <router-link v-if="$store.state.user.data.email.includes('@wavy.co')" :to="'/admin/content/edit/'+content.slug" ><b-button type="is-grey" class="card-button" >{{ $t('buttons.edit') }}</b-button></router-link>

          </div>
        </div>
      </div>
      <footer class="card-footer">
        
      </footer>
    </div>
</template>

<script>
//const fb = require('../../firebaseConfig')
//import EventBus from '../event-bus';

export default {
  name: 'card_video',
  components: {
  },
  props:{
  content:Object
},
methods:{
  redirect(slug){
    window.localStorage.setItem('route', `video/${slug}` )
    this.$router.push({ path: `/account/` })
  }
  
},
computed: {
        authorization:function(){
            if(this.$store.state.user.status == 'gold'){
              return 1
            } else if( this.content.authorization == 'gold')
            {
              if(this.$store.state.user.status == 'silver' || this.$store.state.user.status == 'bronze'){
                return 2
              }
            } 
            else if(this.content.authorization == 'silver')
            {
              if(this.$store.state.user.status == 'silver'){
                return 1
              } else if(this.$store.state.user.status == 'bronze'){
                return 3
              }
            } else if( this.content.authorization == 'bronze')
            {
              return 1
            }
            return 3
                  }
    },
created(){
 
}
}
</script>
