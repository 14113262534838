<template>
    <div class="columns is-multiline is-centered">
        <div class="column is-4" >
            <b-skeleton width="100%" height="100px" :animated="true"></b-skeleton>
            <b-skeleton width="20%" :animated="true"></b-skeleton>
            <b-skeleton width="80%" :animated="true"></b-skeleton>
            <b-skeleton width="100%" height="40px" :animated="true" ></b-skeleton>
        </div>
        <div class="column is-4">
            <b-skeleton width="100%" height="100px" :animated="true"></b-skeleton>
            <b-skeleton width="20%" :animated="true"></b-skeleton>
            <b-skeleton width="80%" :animated="true"></b-skeleton>
            <b-skeleton width="100%" height="40px" :animated="true" ></b-skeleton>
        </div>
        <div class="column is-4" >
            <b-skeleton width="100%" height="100px" :animated="true"></b-skeleton>
            <b-skeleton width="20%" :animated="true"></b-skeleton>
            <b-skeleton width="80%" :animated="true"></b-skeleton>
            <b-skeleton width="100%" height="40px" :animated="true" ></b-skeleton>
        </div>
    </div>
</template>

<script>

export default {
  name: 'loading_card',
  components: {
  },
  data (){
    return {
    }
  },
    methods:{
      },
    watch: {
    }
}
</script>
