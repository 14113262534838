<template>
<div class="container">
    <h1> {{$t('legal.mentions')}}</h1>
<p><strong> </strong><br/>
   {{$t('legal.legal')}} <a href="http://content.wavy.co" target="_blank">content.wavy.co</a> {{$t('legal.legal2')}}
</p>
<h2>{{$t('legal.editor')}}</h2>
<p>{{$t('legal.site')}} <a href="http://content.wavy.co" target="_blank">content.wavy.co</a> {{$t('legal.1')}}</p>
<p><strong>TDLS</strong><strong> </strong><br />
   <strong>SAS </strong>{{$t('legal.2')}}<strong> </strong><strong>3125,69 </strong>€ <br>
   {{$t('legal.3')}}
</p>
<p><strong>9 Boulevard de la Madeleine </strong><strong>75001 Paris</strong><br />
   {{$t('legal.4')}}
</p>
<p>
   {{$t('legal.5')}} <br />
    <br />
  {{$t('legal.6')}}<br />
   {{$t('legal.7')}}
</p>
<p> </p>
<h2>{{$t('legal.8')}}</h2>
<p>{{$t('legal.9')}}<br /></p>



</div>


</template>
<style scoped>
  div > h2 {
      font-size:20px;
      font-weight:700;
      margin-top:10px;
  }
  div > h3 {
      font-size:16px;
      font-weight:600;
      margin-top:5px;
  }
  div{
      margin-top:20px
  }

  div > h1{
      font-size:25px;
      font-weight:900;
  }

</style>

<script>

export default {
    name: 'Mentions',
    data (){
        return {
           
        }
    },
    components:{

    },
    computed: {
        
    },
    created(){
        
    },
    methods:{
    },
    props: {

    }
}
</script>

