<template>
  <div>
    <div class="container" style="margin-bottom:50px;">
      <h2 class="home-title">
        {{content.title}} 
      </h2>
      <div class="columns">
         <div class="column is-5">
          <b-skeleton width="90%" height="100px" :animated="true" v-if="!isMainLoaded"></b-skeleton>
          <iframe width="100%" height="315" :src="content.url" :title="content.title" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen>
          </iframe>
          
        </div>
        <div class="column is-7">
          <div class="columns">
            <div class="column is-12">
              <b-skeleton width="20%" :animated="true" v-if="!isMainLoaded"></b-skeleton>
              <b-tag type="is-primary" >{{$t('menu.video')}}</b-tag>
            
            </div>
          </div>
           <div class="columns">
            <div class="column is-6">              
                <p class="result_description">
                   <b-skeleton width="50%" :animated="true" v-if="!isMainLoaded"></b-skeleton>
                  <b-skeleton width="50%" :animated="true" v-if="!isMainLoaded"></b-skeleton>
                  {{content.description}} 
                </p>
            </div>
          </div>
          <div class="columns">
            <div class="column is-6">
              <b-skeleton width="80%" height="40px" :animated="true" v-if="!isMainLoaded"></b-skeleton>
              <div v-if="isMainLoaded && authorization == 2" class="columns is-centered">
                  <div class="column is-12 has-text-centered">
                    {{$t('content.block-customers')}}
                  </div>
                </div>
                <a href="https://wavy.co?utm_source=wavy-ressources&utm_medium=blocked-content" v-if="isMainLoaded && authorization == 2"><b-button type="is-primary" class="card-button" >{{$t('buttons.discover-wavy')}}</b-button></a>
                <div v-if="isMainLoaded && authorization == 3" class="columns is-centered">
                  <div class="column is-12 has-text-centered" >
                    {{$t('content.finalize')}}
                  </div>
                </div>
                <a @click="redirect(content.slug)" v-if="isMainLoaded && authorization == 3"><b-button type="is-primary" class="card-button" >{{$t('buttons.finalize')}}</b-button></a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="container" style="margin-top:100px;">
       <h3 class="home-subtitle">
       {{$t('content.other-content')}}
      </h3>
      <LoadingCard v-if="!isMainLoaded"></LoadingCard>

      <div class="columns multiline">
          <div v-for="content in link_contents"  v-bind:key="content.id" class="column is-4">
            <CardKit v-if="content.type == 'kit'" :content="content" ></CardKit>
            <CardFiche v-if="content.type=='fp' || content.type=='lb'"   :content="content" ></CardFiche>
            <CardVideo v-if="content.type=='video'" :content="content" ></CardVideo>
          </div>
        </div>
      </div>
  </div>
</template>

<script>
const fb = require('../../../firebaseConfig')
import CardKit from '../blocs/card_kit'
import CardFiche from '../blocs/card_fiche'
import LoadingCard from '../blocs/loading_card'

export default {
  name: 'ficheDetails',
  data (){
        return {
            content:{},
            link_contents:[],
            authorization:2,
            isMainLoaded:false
        }
    },
  components:{
      CardKit,
      CardFiche,
      LoadingCard
    },
  props: {

  },
  methods:{
    redirect(slug){
      window.localStorage.setItem('route', `fiche/${slug}` )
      this.$router.push({ path: `/account/` })
    },
     downloadFiche(){
      window.userengage("event.[Cold]_Fiches",{
        "Value": this.content.slug,
        "Type": this.content.type
      });
      window.open(this.content.url, "_blank")
    }
  },
  created(){
        let here = this
        fb.contentsCollection.where("slug", "==", here.$route.params.slug).get().then(function(docs) {
            here.content =  docs.docs[0].data()
             if(here.$store.state.user.status == 'gold'){
                  here.authorization = 1
                } else if( here.content.authorization == 'gold')
                {
                  if(here.$store.state.user.status == 'silver' || here.$store.state.user.status == 'bronze'){
                    here.authorization = 2
                  }
                } 
                else if(here.content.authorization == 'silver')
                {
                  if(here.$store.state.user.status == 'silver'){
                    here.authorization = 1
                  } else if(here.$store.state.user.status == 'bronze'){
                    here.authorization = 3
                  }
                } else if( here.content.authorization == 'bronze')
                {
                  here.authorization = 1
                }
            if(here.content.type == 'kit'){
                window.location.replace('/kit/'+here.content.slug)
              }
              else if(here.content.type == 'lb'){
                window.location.replace('/livre/'+here.content.slug)
              }
              
              else if(here.content.type == 'tool'){
                window.location.replace('/tool/'+here.content.slug)
              }
              else if(here.content.type == 'visuel'){
                window.location.replace('/')
              }
            here.content.link_contents.forEach(element => {
              fb.contentsCollection.doc(element).get().then(function(doc) {
                here.link_contents.push(doc.data())
              });
            })
            here.isMainLoaded =true
        })
        
}
}
</script>

