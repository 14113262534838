<template>
    <div class="columns is-multiline is-centered">
        <div class="column is-6" style="text-align:center" >
            <h2>
              {{ $t('search.no-result') }}
            </h2>
            <img src="https://res.cloudinary.com/wavy/image/upload/v1601901148/wavy-home/Wavy%20Ressources/undraw_web_search_eetr.png" >
        </div>
    </div>
</template>

<script>

export default {
  name: 'empty_search',
  components: {
  },
  data (){
    return {
    }
  },
    methods:{
      },
    watch: {
    }
}
</script>
