<template>
    <div class="card">
      <div class="card-image" >
          <figure class="image is-1by1">
            <img :src="content.thumbnail" alt="Placeholder image">
          </figure>
          

      </div>       
      <div class="card-content">
        <div class="columns">
          <div class="column is-6">
            <b-tag type="is-grey">Kit {{title}}</b-tag>
          </div>
          {{content.image}}
          <div class="column is-6">
            <img v-if="content.plateforme.find(element=>element.title == 'Instagram')" class="is-pulled-right" src="https://res.cloudinary.com/wavy/image/upload/v1602775849/wavy-home/Wavy%20Ressources/favicon/2835488_453d7.jpg" style="width:25px;margin-left:5px;">
            <img v-if="content.plateforme.find(element=>element.title == 'Facebook')" class="is-pulled-right"  src="https://res.cloudinary.com/wavy/image/upload/v1600339667/wavy-home/iconfinder_Colored_Facebook3_svg_5365678.webp" style="width:25px;">

          </div>
        </div>
        <div class="columns">
          <div class="column is-12">
            <a  v-if="authorization == 1" @click="downloadWithAxios(content.url, content.name)"> <b-button type="is-primary"  class="card-button">{{ $t('buttons.download') }}</b-button></a>
            <div v-if="authorization == 2" class="columns is-centered">
              <div class="column is-12 has-text-centered" >
                {{ $t('content.block-customers') }}
              </div>
             </div>
            <a href="https://wavy.co?utm_source=wavy-ressources&utm_medium=blocked-content" v-if="authorization == 2"><b-button type="is-primary" class="card-button" >{{ $t('buttons.discover-wavys') }}</b-button></a>
             <div v-if="authorization == 3" class="columns is-centered">
              <div class="column is-12 has-text-centered" :href="content.url">
                {{ $t('content.finalize') }}
              </div>
             </div>
            <a :href="'/account'" v-if="authorization == 3"><b-button type="is-primary" class="card-button" >{{ $t('buttons.finalize') }}</b-button></a>
            <router-link v-if="$store.state.user.data.email.includes('@wavy.co')" :to="'/admin/content/edit/'+content.slug" ><b-button type="is-grey" class="card-button" >{{ $t('buttons.edit') }}</b-button></router-link>

          </div>
        </div>
        <div class="columns" v-if="false">
          <div class="column is-12">
            <b-button type="is-blue-light" class="card-button" >{{ $t('content.share-fb') }}</b-button>
          </div>
       </div>
      </div>
    </div>
</template>

<script>
//const fb = require('../../firebaseConfig')
//import EventBus from '../event-bus';
const axios = require('axios');

export default {
  name: 'card_visuel',
  components: {
  },
  
  props:{
  content:Object,
  title:String,
  
},
methods:{
  downloadWithAxios(url, title) {
    let here = this
      axios({
        method: 'get',
        url,
        responseType: 'arraybuffer',
      })
        .then((response) => {
          window.userengage("event.[Cold]_Visuel",{
            "Value": this.title,
            "name": this.content.name
          })
          here.forceFileDownload(response, title, url)
        })
        .catch((err) => console.log(err))
    },
    forceFileDownload(response, title, urlInit) {
      const url = window.URL.createObjectURL(new Blob([response.data]))
      const link = document.createElement('a')
      link.href = url
      let idx = urlInit.lastIndexOf('.')
      let idx2 = title.lastIndexOf('.')
      link.setAttribute('download', title.substring(idx2 + 1)+'.'+urlInit.substring(idx + 1).trim())
      document.body.appendChild(link)
      link.click()
    },
},
computed: {
        authorization:function(){
            if(this.$store.state.user.status == 'gold'){
              return 1
            } else if( this.content.authorization == 'gold')
            {
              if(this.$store.state.user.status == 'silver' || this.$store.state.user.status == 'bronze'){
                return 2
              }
            } 
            else if(this.content.authorization == 'silver')
            {
              if(this.$store.state.user.status == 'silver'){
                return 1
              } else if(this.$store.state.user.status == 'bronze'){
                return 3
              }
            } else if( this.content.authorization == 'bronze')
            {
              return 1
            }
            return 3
                  }
    },
created(){

}
}
</script>
