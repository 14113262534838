<template>
        <div class="columns custom_navbar">
            <div class="column is-2 hideMobile">
                <div class="columns is-centered">
                    <div class="column is-8 has-text-centered">
                        <img
                            src="https://res.cloudinary.com/wavy/image/upload/v1558086078/wavy-home/landing_page/WAVY_LOGOTYPE2_BLEU2.png"
                            alt="Wavy Ressources"
                            class='logo_header'
                        >
                    </div>
                </div>
            </div>

            <div class="column is-8" v-if="$store.state.user.loggedIn" >
                <div class="search_div">
                    <input type="text" class="input_search" v-model="search" :placeholder="$t('search.placeholder')"  @focus="searchVisible = true" @blur="hideMenu"
                    >
                </div>
                
                <div class="header_result container" v-if="searchVisible" >
                    <b-button type="is-grey" class="card-button" @click="resetSearch">{{$t('search.close')}}</b-button>
                    <div v-show="results_search.length == 0 && search != 0" class="result_search columns" >
                        <div class="column is-12">
                            {{ $t('search.close') }}
                        </div>
                    </div>
                    <div v-show="results_search.length == 0 && search == 0" class="result_search columns" >
                        <div class="column is-12">
                            {{ $t('search.tape-to-search') }}
                        </div>
                    </div>
                    <div v-for="result in results_search" v-bind:key="result.id" class="result_search columns" v-show="results_search.length > 0">
                        <div class="column is-3">
                            <a :href="result.slug">
                            <img
                                :src="result.thumbnail"
                                style="width:100%"
                            >
                            </a>
                        </div>
                        <div class="column is-9">
                            <a class="columns" :href="result.slug">
                                <div class="column">
                                     <b-tag type="is-primary">{{ $t('menu.fp') }}</b-tag>
                                    <h2 class="result_title">
                                        {{result.title}} 
                                    </h2>
                                   
                                    <p class="result_description">
                                        {{result.description}} 
                                    </p>
                                </div>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            <div class="column is-2 hideMobile">
                <div class="columns is-centered">
                    <div class="column is-8 has-text-centered">
                         <LocaleSwitcher />
                    </div>
                </div>
            </div>
        </div>
        
</template>

<script>
//const fb = require('../../firebaseConfig')
//import EventBus from '../event-bus';
import algoliasearch from 'algoliasearch/lite';
import LocaleSwitcher from "@/components/i18n/LocaleSwitcher"
const client = algoliasearch('BOZ2ATNMBB', 'f661c7a09a74e550ea676b661ebfd06c');

export default {
  name: 'header',
  components: {
      LocaleSwitcher
  },
  data (){
    return {
        search:"",
        results_search:[],
        searchVisible:false
    }
  },
    methods:{
        hideMenu(){
            setTimeout(() => {
              this.searchVisible = false
          }, 500)
        },
        resetSearch(){
            this.searchVisible = false
            this.search=""
        },
        setLocale(locale) {
        this.$i18n.locale = locale
        }
      },
    watch: {
        search: function (val) {
            let here = this
            if(val && val != ""){
            const index = client.initIndex('content');
                index.search(val,{filters:`language:${here.$i18n.locale}`,hitsPerPage: 5}).then(({ hits }) => {
                    this.results_search = hits
                    this.results_search.forEach(element => {
                        if(element.type == 'kit'){
                            element.slug = '/kit/'+element.slug
                        }
                        else if(element.type == 'lb' || element.type == 'fp' ){
                            element.slug = '/fiche/'+element.slug
                        }
                    });
                });
            }
            else {
                this.results_search = []
            }
        },
    }
}
</script>
