<template>
  <div>
    <div class="container" style="margin-bottom:50px;">
      <div class="container">
           <div class="columns is-centered">
                <div class="is-10 column ">
                    <h2 class="home-title">
                        {{ $t('account.my-account') }}
                    </h2>
            </div>
        </div>
      </div>
      <div class="container">
        <div class="columns is-centered">
            <div class="is-10 column message_account">
                <div class="columns">
                    <div class="column is-8">
                        <span v-if="status == 'gold'">
                            <h3>{{ $t('account.family-member-1') }} </h3>
                            <p>
                                {{ $t('account.family-member-2') }}
                            </p>
                        </span>
                        <span v-else>
                            <h3> {{ $t('account.not-member') }}</h3>
                            <p  v-if="status == 'bronze'">
                                {{ $t('account.member-bronze') }}<br>
                            </p>
                            <p  v-if="status == 'silver'">
                                {{ $t('account.member-silver') }}<br>
                                {{ $t('account.member-silver-2') }}
                            </p>
                        </span>
                    </div>
                    <div class="column is-4">
                        <span  v-if="status !== 'gold'">
                            <a href="https://wavy.co"><b-button type="is-primary" class="card-button button_account"  >{{ $t('buttons.discover-wavy') }}</b-button></a>
                        </span>
                    </div>
                </div>
            </div>
        </div>
      </div>
      <div class="container">
        <div class="columns is-centered">
            <div class="is-10 column">
                <div class="columns">
                    <div class="column is-6">
                        <b-field :label="$t('account.email')">
                            <b-input v-model="user.email" disabled></b-input>
                        </b-field>
                    </div>
                    <div class="column is-6" >
                        <b-field :label="$t('account.phone')">
                            <b-input v-model="user.details.phone_number"></b-input>
                        </b-field>
                    </div>
                </div>
                <span v-if="(company && company.status && company.status != 'Client') || (company == null)">
                    <div class="columns" >
                        <div class="column is-12">
                            <b-field :label="$t('account.salon-name')">
                                <b-input v-model="user.details.nom_du_salon"></b-input>
                            </b-field>
                        </div>
                    </div>
                    <div class="columns">
                        <div class="column is-6">
                            <b-field :label="$t('account.zipcode')">
                                <b-input v-model="user.details.zipcode"></b-input>
                            </b-field>
                        </div>
                        <div class="column is-6">
                            <b-field :label="$t('account.city')">
                                <b-input v-model="user.details.city"></b-input>
                            </b-field>
                        </div>
                    </div>
                    
                    <div class="columns">
                        <div class="column is-12">
                            <b-field :label="$t('account.type')" >
                                <b-select :placeholder="$t('account.type')"  expanded v-model="user.details.type_de_salon">   
                                    <option
                                        v-for="option in type_options"
                                        :value="option.id"
                                        :key="option.id">
                                        {{ option.name }}
                                    </option>
                                </b-select>
                            </b-field>
                        </div>
                    </div>
                    <div class="columns">
                        <div class="column is-12">
                            <b-field :label="$t('account.status')" >
                                <b-select :placeholder="$t('account.status')"  expanded v-model="user.details.statut">   
                                    <option
                                        v-for="option in statut_options"
                                        :value="option.id"
                                        :key="option.id">
                                        {{ option.name }}
                                    </option>
                                </b-select>
                            </b-field>
                        </div>
                    </div>
                    <div class="columns">
                        <div class="column is-12">
                            <b-field :label="$t('account.software_question')" >
                                <b-select :placeholder="$t('account.software_question')"  expanded v-model="user.details.logiciel">   
                                    <option
                                        v-for="option in logiciel_options"
                                        :value="option.id"
                                        :key="option.id">
                                        {{ option.name }}
                                    </option>
                                </b-select>
                            </b-field>
                        </div>
                    </div>
                </span>
                 <div class="columns is-centered">
                    <div class="column is-6">
                        <b-button type="is-primary" class="card-button" @click="saveContent" >{{$t('buttons.save')}}</b-button>
                    </div>
                 </div>
            </div>
        </div>
    </div>
    </div>
  </div>
</template>

<script>
//const route = 'http://localhost:5001/wavy-ressources/us-central1/app/'
const route = "https://us-central1-wavy-ressources.cloudfunctions.net/app"

const fb = require('../../../firebaseConfig')
//const index = client.initIndex('content');
const axios = require('axios');
const md5 = require('md5')
export default {
    name: 'addContent',
    data (){
        return {
            user:{},
            company:{},
            statut_options:[
              {
                id: "Gérant d'un salon",
                name: this.$t('account.statut_salon.owner')
              },
              {
                id: 'Manager',
                name: this.$t('account.statut_salon.manager')
              },
              {
                id: "En cours de reprise / de création d'un salon",
                name: this.$t('account.statut_salon.ongoing')
              },
              {
                id: 'Etudiant',
                name: this.$t('account.statut_salon.student')
              },
              {
                id: "Employé d'un salon	",
                name: this.$t('account.statut_salon.employe')
              }
          ],
            type_options:[
              {
                id: 'Salon de coiffure',
                name: this.$t('account.type_salon.hairdresser')
              },
              {
                id: 'Salon de beauté',
                name: this.$t('account.type_salon.beauty')
              },
              {
                id: 'Barbershop',
                name: this.$t('account.type_salon.barber')
              },
              {
                id: 'Coiffure à domicile',
                name: this.$t('account.type_salon.hair_dom')
              },
              {
                id: 'Esthéticienne à domicile',
                name: this.$t('account.type_salon.beauty_dom')
              },
              {
                id: 'Spa',
                name: this.$t('account.type_salon.spa')
              },
              {
                id: 'Onglerie',
                name: this.$t('account.type_salon.nails')
              },
              {
                id: 'Autre',
                name: this.$t('account.type_salon.other')
              },
            ],
            logiciel_options:[
              {
                id: 'wavy',
                name: 'Wavy'
              },
              {
                id: 'ikosoft',
                name: 'Ikosoft / Merlin'
              },
              {
                id: 'flexy',
                name: 'Flexy'
              },
              {
                id: 'planity',
                name: 'Planity'
              },
              {
                id: '3ci',
                name: '3CI'
              },
              {
                id: 'shortcuts',
                name: 'Shortcuts'
              },
              {
                id: 'kalendes',
                name: 'Kalendes'
              },
              {
                id: 'uala',
                name: 'uala'
              },
              {
                id: 'booksy',
                name: 'Booksy'
              },
              {
                id: 'papier',
                name: this.$t('account.software.paper')
              },
              {
                id: 'autre',
                name: this.$t('account.software.other')
              }
          ]
        }
    },
    components:{

    },
    computed: {
        
    },
    created(){
        this.user = this.$store.state.user.data
        this.status = this.$store.state.user.status
        this.company = this.$store.state.user.company
    },
    methods:{
        saveContent: function() {
            let here = this
            fb.auth.currentUser.getIdToken(true).then(function(idToken) {
               window.localStorage.setItem('token', idToken )
               let tempData = here.user.details
               tempData.email = here.user.email
               delete tempData.type_de_prospect
               delete tempData.name
               Object.keys(tempData).forEach((key) => (tempData[key] == "" || tempData[key] == null) && delete tempData[key]);
               axios.post(`${route}/user/edit/${md5(here.user.email)}/`, tempData, {headers : {"token":idToken}})
                .then(function (response) {
                  if(response.data.status == "ok"){
                       here.$buefy.notification.open({
                            message: 'Modifications enregistrées !',
                            type: 'blue-light',
                            duration:3000
                        })
                        here.$store.dispatch("fetchUserDetails", here.user.details);
                       
                        if(here.$store.state.user.company && here.$store.state.user.company.status == "Client"){
                          console.log("gold status Account")
                            here.$store.dispatch("updateStatus", "gold")
                        }
                            else if(here.$store.state.user.data.details.phone_number && here.$store.state.user.data.details.statut && here.$store.state.user.data.details.city && here.$store.state.user.data.details.zipcode && here.$store.state.user.data.details.nom_du_salon && here.$store.state.user.data.details.type_de_salon){
                            here.$store.dispatch("updateStatus", "silver")
                            }
                            else {
                                here.$store.dispatch("updateStatus", "bronze")
                            }
                        here.user = here.$store.state.user.data
                        here.status = here.$store.state.user.status
                        here.company = here.$store.state.user.company
                        window.userengage("event.[Cold]_SignUp_Platform",{
                          "Value": "full"
                        })
                        if(window.localStorage.getItem('route')){
                          let route = window.localStorage.getItem('route')
                          window.localStorage.removeItem('route')
                          
                            here.$router.push({ path: `/${route}` })
                          
                          
                        }
                  } else {
                            here.$buefy.notification.open({
                            message: 'Erreur, veuillez réessayer',
                            type: 'error',
                            duration:3000
                        })
                  }
                })
                .catch(function (error){
                  console.log(error)
                })    
            }) .catch(function (error){
                  console.log(error)
                }) 
                
        },
    },
    props: {

    }
}
</script>

