import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";

Vue.use(Vuex);
export const store = new Vuex.Store({
  plugins: [createPersistedState()],
  state: {
    user: {
      loggedIn: false,
      data: {},
      token:'',
      company:{
        status:'not found'
      },
      status:"bronze"
    }
  },
  getters: {
    user(state){
      return state.user
    }
  },
  mutations: {
    SET_LOGGED_IN(state, value) {
      state.user.loggedIn = value;
    },
    SET_USER(state, data) {
      if(data){
        state.user.data.email = data.email
        state.user.data.id = data.id
      } else{
        state.user.data = {}
      }
    },
    SET_USER_DETAILS(state, data) {
      if(data){
        state.user.data.details = data
      } else{
        state.user.data.details = {}
      }
    },
    SET_COMPANY(state, data) {
      state.user.company = data;
    },
    SET_STATUS(state, data){
      console.log(data)
      state.user.status = data
    }
  },
  actions: {
    fetchUser({ commit }, user) {
      commit("SET_LOGGED_IN", user !== null);
      if (user) {
        commit("SET_USER", {
          email: user.email,
          id: user.uid
        });
      } else {
        commit("SET_COMPANY", null);
        commit("SET_USER", null);
      }
    },
    fetchUserDetails({ commit }, user) {
      if (user) {
        console.log("user" + user.type_de_prospect)
        commit("SET_USER_DETAILS", {
          type_de_prospect: user.type_de_prospect,
          statut:user.statut,
          nom_du_salon:user.nom_du_salon,
          type_de_salon:user.type_de_salon,
          zipcode:user.zipcode,
          city:user.city,
          name:user.name,
          phone_number:user.phone_number,
          logiciel:user.logiciel
        });
      } else {
        commit("SET_COMPANY", null);
        commit("SET_USER", null);
      }
    },
    fetchCompany({ commit }, company) {
      if (company) {
        commit("SET_COMPANY", company);
      } else {
        commit("SET_COMPANY", null);
      }
    },
    updateStatus({ commit }, status) {
      commit("SET_STATUS", status);
    }
  }
});