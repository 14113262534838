<template>
  <div>
    <div class="container" style="margin-bottom:50px;">
      <h2 class="home-title">
        Ajouter un contenu
      </h2>
      <div class="container">
        <div class="columns">
            <div class="is-6 column">
                <div class="columns">
                    <div class="column is-6">
                        <b-field label="Nom de la catégorie">
                            <b-input v-model="content.name"></b-input>
                        </b-field>
                    </div>
                    <div class="column is-6">
                        <b-field label="Type de catégorie">
                            <b-autocomplete
                                placeholder="ex : Fiches Pratiques"
                                :keep-first="false"
                                :open-on-focus="true"
                                :data="[{'id':'fp', 'title':'Fiches Pratiques'},{'id':'kit', 'title':'Kit'}, {'id':'lb', 'title':'Livre Blanc'}]"
                                field="title"
                                @select="option => (content.type = option.id)"
                                :clearable="true"
                            >
                            </b-autocomplete>
                        </b-field>
                    </div>
                </div>
                <div class="columns">
                    <div class="column is-6">
                        <b-field label="Langue de la catégorie">
                           <b-select placeholder="ex :Français"  expanded v-model="content.language">   
                                <option value="fr">🇫🇷  Français</option>
                                <option value="es">🇪🇸  Espagnol</option>
                                <option value="en">🇬🇧  Anglais </option>
                            </b-select>
                        </b-field>
                    </div>
                </div>
                 <div class="columns is-centered">
                    <div class="column is-6">
                        <b-button type="is-primary" class="card-button" @click="saveContent" >Enregistrer</b-button>
                    </div>
                 </div>
            </div>
             <div class="column is-1">
             </div>
        </div>
    </div>
    </div>
  </div>
</template>

<script>
const fb = require('../../../firebaseConfig')

export default {
    name: 'addContent',
    data (){
        return {
            content:{
                type:"",
                name:''
            }
        }
    },
    components:{
       
    },
    computed: {
        slug(){
            return this.sanitizeTitle(this.content.name)
        }
    },
    created(){
        if(!this.$store.state.user.data.email.includes('wavy.co')){
            this.$router.push('/')
        }
    },
    methods:{
        saveContent(){
            let here = this
            if(here.content.type != 'kit'){
            fb.categoriesCollection.doc(here.slug).set(here.content)
            .then(() => {
                here.$buefy.notification.open({
                    message: 'Modification enregistrées !',
                    type: 'is-bcd-green',
                    duration:3000
                });
                window.location.reload();
            })
            }else{
                fb.themesCollection.doc(here.slug).set(here.content)
                .then(() => {
                    here.$buefy.notification.open({
                        message: 'Modification enregistrées !',
                        type: 'is-bcd-green',
                        duration:3000
                    });
                    window.location.reload();
                });
            }
        },
        sanitizeTitle: function(title) {
            var slug = "";
            // Change to lower case
            var titleLower = title.toLowerCase();
            // Letter "e"
            slug = titleLower.replace(/e|é|è|ẽ|ẻ|ẹ|ê|ế|ề|ễ|ể|ệ/gi, 'e');
            // Letter "a"
            slug = slug.replace(/a|á|à|ã|ả|ạ|ă|ắ|ằ|ẵ|ẳ|ặ|â|ấ|ầ|ẫ|ẩ|ậ/gi, 'a');
            // Letter "o"
            slug = slug.replace(/o|ó|ò|õ|ỏ|ọ|ô|ố|ồ|ỗ|ổ|ộ|ơ|ớ|ờ|ỡ|ở|ợ/gi, 'o');
            // Letter "u"
            slug = slug.replace(/u|ú|ù|ũ|ủ|ụ|ư|ứ|ừ|ữ|ử|ự/gi, 'u');
            // Letter "d"
            slug = slug.replace(/đ/gi, 'd');
            // Trim the last whitespace
            slug = slug.replace(/\s*$/g, '');
            // Change whitespace to "-"
            slug = slug.replace(/\s+/g, '-');
            slug = slug.replace(/[&\\#,+()$~%.'":*?<>{}]/g,'');
            
        return slug;
        }
    },
    props: {

    }
}
</script>

