<template>
  <div>
    <div class="container" style="margin-bottom:50px;">
      <h2 class="home-title">
       {{ $t('menu.fp')}}
      </h2>
      <LoadingCard v-if="!isLoaded"></LoadingCard>
      <EmptySearch v-if="isLoaded && contents.length == 0"></EmptySearch>

      <div class="columns is-multiline is-centered">
        
         <div v-for="content in computedDocs" v-bind:key="content.id" class="column is-4">
          <CardFiche v-if="content.type=='fp'" :content="content" ></CardFiche>
        </div>
      </div>
      <div class="columns is-centered" style="margin-top:20px">
         <div class="column is-4">
           <b-button type="is-grey" class="card-button" v-if="isLoaded && limit < contents.length" @click="limit = limit+6" >{{$t('buttons.see-more')}}</b-button>
         </div>
       </div>
    </div>
  </div>
</template>

<script>
import CardFiche from '../blocs/card_fiche'
const fb = require('../../../firebaseConfig')
import LoadingCard from '../blocs/loading_card'
import EmptySearch from '../blocs/empty_search'

export default {
  name: 'Kit',
  data (){
        return {
            contents:[],
            isLoaded:false,
            theme:'',
            limit:6
        }
    },
  components:{
      CardFiche,
      LoadingCard,
      EmptySearch
  },
  props: {

  },
  watch:{
      lang:function(){
          this.contents = []
          this.isLoaded = false
          this.getContent()
      }
  },
   methods:{
    getContent:function(){
      
      let here = this
      fb.contentsCollection.where("type", "==", "fp").orderBy('updatedAt', 'desc').get().then(function(docs) {
                here.isLoaded = true
                docs.forEach(doc => {
                    var contentTemp = doc.data()
                    contentTemp.id = doc.id
                    if( (here.theme && here.theme == contentTemp.tag) || (!here.theme || here.theme == '') ){
                      if((contentTemp.language == here.$i18n.locale) || (!contentTemp.language && here.$i18n.locale == 'fr' )){
                        here.contents.push(contentTemp)
                      }
                    }
                });
            })
    }
   },
  created(){
        let here = this
        if(here.$route.params.slug){
           fb.categoriesCollection.doc(here.$route.params.slug).get().then(function(doc) {
            here.theme = doc.data().name       
          })
        }
        this.getContent()

        
  },
  computed:{
  computedDocs(){
    return this.limit ? this.contents.slice(0,this.limit) : this.contents
  },
  lang(){
    return this.$attrs.lang
  },
}
}
</script>

