<template>
  <div>
      
      <div class="columns is-centered">
        <div class="column is-6" v-if="!sentEmail">
          <h1 class="home-title">
           {{$t('login.login')}}
          </h1>
           <b-field>
            <b-input :placeholder=" $t('login.email')"
                v-model="email"
                type="email"
                icon="email">
            </b-input>
        </b-field>
        <p style="text-align:center">
           {{$t('login.check-rgpd')}} <a href="https://content.wavy.co/rgpd" target="_blank" style="text-decoration: underline;"> {{$t('login.check-rgpd2')}}</a>
        </p>
        </div>
        <div class="column is-6" v-if="sentEmail">
          <b-message title="Cliquez sur le lien magique pour vous connecter"   type="is-primary">
             {{$t('login.email-sent')}} {{email}}.<br>
             {{$t('login.click-it')}}<br>
            
          </b-message>
          <p @click="sentEmail = !sentEmail">
             {{$t('login.no-email')}}
            <br>
           <span style="cursor:pointer"> {{$t('login.nothing')}} </span>
          </p>
        </div>
        
      </div>
      <div class="columns is-centered" v-if="!sentEmail">
        <div class="column is-6">
          <b-button type="is-primary" class="card-button" @click="sendSignUpLink" >{{$t('login.login')}}</b-button>
        </div>
      </div>
  </div>
</template>

<script>
 
const fb = require('../../../firebaseConfig')
const md5 = require('md5')
export default {
  name: 'Home',
  data (){
    return {
      actionCodeSettings: {
        // URL you want to redirect back to. The domain (www.example.com) for this
        // URL must be whitelisted in the Firebase Console.
        url: 'https://content.wavy.co/login/finalize',
        // This must be true.
        handleCodeInApp: true
      },
      email:'',
      sentEmail:false
    }
  },
  components:{
    },
  props: {
  },
  methods:{
    sendSignUpLink(){
      let here = this
      console.log(here.$i18n.locale)
      fb.auth.languageCode = here.$i18n.locale
      fb.auth.sendSignInLinkToEmail(here.email, here.actionCodeSettings)
        .then(function() {
          if(window.UE){  
            window.UE.resetAuth({
        apiKey: "kA9jGs",
        user_id: md5(here.email.toLowerCase()),
            email:here.email.toLowerCase()
      });
          window.userengage("event.[Cold]_SignUp_Platform",{
              "Value": "init"
            })
          }
          else {console.log('error user')}
          // The link was successfully sent. Inform the user.
          // Save the email locally so you don't need to ask the user for it again
          // if they open the link on the same device.
          window.localStorage.setItem('emailForSignIn', here.email)
          here.sentEmail = true
        })
        .catch(function(error) {
          console.log(error)
          console.log(error.code)
        });
    }
  }
}
</script>

